import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { AppBreadcrumbComponent } from 'src/app/core/layout/components/breadcrumb/app.breadcrumb.component';
import { AppTopbarComponent } from 'src/app/core/layout/components/topbar/app.topbar.component';
import { ChatService } from '../private/chat/service/chat.service';
import { AccessRecapComponent } from './components/access-recap/access-recap.component';
import { AddBotDialogComponent } from './components/add-bot-dialog/add-bot-dialog.component';
import { AddTagModalComponent } from './components/add-tag-modal/add-tag-modal.component';
import { AiModelSelectComponent } from './components/ai-model-select/ai-model-select.component';
import { AiModelsTableModalComponent } from './components/ai-models-table-modal/ai-models-table-modal.component';
import { ApiKeyTableComponent } from './components/api-key-table/api-key-table.component';
import { ApplyPromoCodeModalComponent } from './components/apply-promo-code-modal/apply-promo-code-modal.component';
import { AssistantCardComponent } from './components/assistant-card/assistant-card.component';
import { AssistantChatButtonsComponent } from './components/assistant-chat-buttons/assistant-chat-buttons.component';
import { AssistantCreationWizardComponent } from './components/assistant-creation-wizard/assistant-creation-wizard.component';
import { CommercialWeDoComponent } from './components/assistant-creation-wizard/commercial/commercial-we-do/commercial-we-do.component';
import { CommercialWeDontComponent } from './components/assistant-creation-wizard/commercial/commercial-we-dont/commercial-we-dont.component';
import { CommercialComponent } from './components/assistant-creation-wizard/commercial/commercial.component';
import { CharacterComponent } from './components/assistant-creation-wizard/common/character/character.component';
import { OrganizationComponent } from './components/assistant-creation-wizard/common/organization/organization.component';
import { RecapComponent } from './components/assistant-creation-wizard/common/recap/recap.component';
import { TargetAudienceComponent } from './components/assistant-creation-wizard/common/target-audience/target-audience.component';
import { InternalExpertiseComponent } from './components/assistant-creation-wizard/internal-kb/internal-expertise/internal-expertise.component';
import { InternalKbComponent } from './components/assistant-creation-wizard/internal-kb/internal-kb.component';
import { InternalReplyExtraDetailsComponent } from './components/assistant-creation-wizard/internal-kb/internal-reply-extra-details/internal-reply-extra-details.component';
import { InternalTargetLanguageComponent } from './components/assistant-creation-wizard/internal-kb/internal-target-language/internal-target-language.component';
import { BaseInputElementComponent } from './components/base-input-element/base-input-element.component';
import { BotDetailsModalComponent } from './components/bot-details-modal/bot-details-modal.component';
import { BuyCreditsModalComponent } from './components/buy-credits-modal/buy-credits-modal.component';
import { ChangeUsersPermissionModalComponent } from './components/change-users-permission-modal/change-users-permission-modal.component';
import { ChatConfigurationSidebarComponent } from './components/chat-configuration-sidebar/chat-configuration-sidebar.component';
import { ChatKbSpanComponent } from './components/chat-kb-span/chat-kb-span.component';
import { ChatLoaderComponent } from './components/chat-loader/chat-loader.component';
import { ConfigureChatModalComponent } from './components/configure-chat-modal/configure-chat-modal.component';
import { ContinueChatModalComponent } from './components/continue-chat-modal/continue-chat-modal.component';
import { CreateApiKeyModalComponent } from './components/create-api-key-modal/create-api-key-modal.component';
import { CreateAssistantUseModalComponent } from './components/create-assistant-use-modal/create-assistant-use-modal.component';
import { CreateGroupModalComponent } from './components/create-group-modal/create-group-modal.component';
import { CreatePipelineModalComponent } from './components/create-pipeline-modal/create-pipeline-modal.component';
import { CreateUsersGroupModalComponent } from './components/create-users-group-modal/create-users-group-modal.component';
import { DeleteBotModalComponent } from './components/delete-bot-modal/delete-bot-modal.component';
import { ExportChatModalComponent } from './components/export-chat-modal/export-chat-modal.component';
import { FormFieldsTableComponent } from './components/form-fields-table/form-fields-table.component';
import { FunctionalityNotAvailableComponent } from './components/functionality-not-available/functionality-not-available.component';
import { IconComponent } from './components/icon/icon.component';
import { InviteUsersModalComponent } from './components/invite-users-modal/invite-users-modal.component';
import { KbSettingsModalComponent } from './components/kb-settings-modal/kb-settings-modal.component';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';
import { LeaveEditedPageAlertComponent } from './components/leave-edited-page-alert/leave-edited-page-alert.component';
import { MainTopbarComponent } from './components/main-topbar/main-topbar.component';
import { ManageSourcePermissionsModalComponent } from './components/manage-source-permissions-modal/manage-source-permissions-modal.component';
import { MarketplaceItemCardComponent } from './components/marketplace-item-card/marketplace-item-card.component';
import { MassiveKBActionsModalComponent } from './components/massive-kb-actions-modal/massive-kb-actions-modal.component';
import { NotVerifiedWarningComponent } from './components/not-verified-warning/not-verified-warning.component';
import { PricingCardComponent } from './components/pricing-card/pricing-card.component';
import { ProductDetailsModalComponent } from './components/product-details-modal/product-details-modal.component';
import { SelectAgencyModalComponent } from './components/select-agency-modal/select-agency-modal.component';
import { ShareCreationModalComponent } from './components/share-creation-modal/share-creation-modal.component';
import { ShareModalWrapperComponent } from './components/share-modal-wrapper/share-modal-wrapper.component';
import { ShareComponent } from './components/share/share.component';
import { SourceTagsListComponent } from './components/source-tags-list/source-tags-list.component';
import { StarterMessageHistoryModalComponent } from './components/starter-message-history-modal/starter-message-history-modal.component';
import { AddestramentoComponent } from './components/starting-guide/addestramento/addestramento.component';
import { CreazioneComponent } from './components/starting-guide/creazione/creazione.component';
import { StartingGuideComponent } from './components/starting-guide/starting-guide.component';
import { UtilizzoComponent } from './components/starting-guide/utilizzo/utilizzo.component';
import { StartingModalComponent } from './components/starting-modal/starting-modal.component';
import { ThreeDotsComponent } from './components/three-dots/three-dots.component';
import { TrainingStatusBadgeComponent } from './components/training-status-badge/training-status-badge.component';
import { UpdatePwaModalComponent } from './components/update-pwa-modal/update-pwa-modal.component';
import { UploadBotImageModalComponent } from './components/upload-bot-image-modal/upload-bot-image-modal.component';
import { AppendToBodyDirective } from './directives/append-to-body.directive';
import { AssistantBackgroundDirective } from './directives/assistant-background.directive';
import { BackButtonDirective } from './directives/back-button.directive';
import { CtrlEnterNewlineDirective } from './directives/ctrl-enter-newline.directive';
import { FallbackImageDirective } from './directives/fallback-image.directive';
import { GalleryLayoutDirective } from './directives/gallery-layout.directive';
import { GlueLabsEnabledDirective } from './directives/glue-labs-enabled.directive';
import { HideIfMarketplaceDirective } from './directives/hide-if-marketplace.directive';
import { HideIfNoBotDirective } from './directives/hide-if-no-bot.directive';
import { HideInSegmentDirective } from './directives/hide-in-assistant.directive';
import { HighightWordDirective } from './directives/highight-word.directive';
import { MaskInputDirective } from './directives/mask_input.directive';
import { BlockCopyPasteDirective } from './directives/prevent-cut-copy-paste.directive';
import { ProfileSettingAllowedDirective } from './directives/profile-setting-allowed.directive';
import { ResponsiveColumnsDirective } from './directives/responsive-columns.directive';
import { StripHtmlDirective } from './directives/strip-html.directive';
import { AnalyticsFilterSidebarComponent } from './layout-components/analytics-filter-sidebar/analytics-filter-sidebar.component';
import { ChatTopbarComponent } from './layout-components/chat-topbar/chat-topbar.component';
import { ConfigurationSidebarComponent } from './layout-components/configuration-sidebar/configuration-sidebar.component';
import { HistorySidebarComponent } from './layout-components/history-sidebar/history-sidebar.component';
import { KnowledgeBaseSidebarComponent } from './layout-components/knowledge-base-sidebar/knowledge-base-sidebar.component';
import { LatestAssistantsSidebarComponent } from './layout-components/latest-assistants-sidebar/latest-assistants-sidebar.component';
import { MarketplaceSidebarComponent } from './layout-components/marketplace-sidebar/marketplace-sidebar.component';
import { PrimeNgModule } from './modules/primeng.module';
import { AvailableLinkPipe } from './pipes/available-link.pipe';
import { CompanyLevelPipe } from './pipes/company-level.pipe';
import { ConversationSourcePipe } from './pipes/conversation-source.pipe';
import { CrawlingMethodPipe } from './pipes/crawling-method.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DatestringToDatePipe } from './pipes/datestring-to-date.pipe';
import { FilterMarketplacePipe } from './pipes/filter-marketplace.pipe';
import { KeepSvgAsTextPipe } from './pipes/keep-svg.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { LastMessagePipe } from './pipes/last-message.pipe';
import { MarkdownParserPipe } from './pipes/markdown-parser.pipe';
import { MarketplaceUsernameParserPipe } from './pipes/marketplace-username-parser.pipe';
import { PresetsPipe } from './pipes/presets.pipe';
import { RecapWizardHeaderPipe } from './pipes/recap-wizard-header.pipe';
import { SmartTruncatePipe } from './pipes/smart-truncate.pipe';
import { SortKeyvaluePipe } from './pipes/sort-keyvalue.pipe';
import { TrainingStatusPipe } from './pipes/training-status.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

const MODALS = [
  AddBotDialogComponent,
  DeleteBotModalComponent,
  BotDetailsModalComponent,
  ConfigureChatModalComponent,
  ShareCreationModalComponent,
  CreateGroupModalComponent,
  StarterMessageHistoryModalComponent,
  ChangeUsersPermissionModalComponent,
  KbSettingsModalComponent,
  CreateUsersGroupModalComponent,
  ManageSourcePermissionsModalComponent,
  ExportChatModalComponent,
  CreateApiKeyModalComponent,
  ContinueChatModalComponent,
  AddTagModalComponent,
  MassiveKBActionsModalComponent,
  ProductDetailsModalComponent,
  CreateAssistantUseModalComponent,
  InviteUsersModalComponent,
  ApplyPromoCodeModalComponent,
  BuyCreditsModalComponent,
  LeaveEditedPageAlertComponent,
  CreatePipelineModalComponent,
  UploadBotImageModalComponent,
  UpdatePwaModalComponent,
  AiModelsTableModalComponent,
];

const ASSISTANT_CREATION_COMPONENTS = [
  AssistantCreationWizardComponent,
  InternalKbComponent,
  CommercialComponent,
  CharacterComponent,
  CommercialWeDoComponent,
  CommercialWeDontComponent,
  OrganizationComponent,
  InternalExpertiseComponent,
  TargetAudienceComponent,
  InternalTargetLanguageComponent,
  InternalReplyExtraDetailsComponent,
];

const LAYOUT_COMPONENTS = [
  ChatLoaderComponent,
  ConfigurationSidebarComponent,
  LatestAssistantsSidebarComponent,
  AppTopbarComponent,
  AppBreadcrumbComponent,
  ChatTopbarComponent,
  MainTopbarComponent,
  HistorySidebarComponent,
  NotVerifiedWarningComponent,
  HistorySidebarComponent,
  KnowledgeBaseSidebarComponent,
  MarketplaceSidebarComponent,
  AnalyticsFilterSidebarComponent,
];

const COMPONENTS = [
  ThreeDotsComponent,
  FormFieldsTableComponent,
  IconComponent,
  ChatKbSpanComponent,
  BaseInputElementComponent,
  StartingModalComponent,
  FunctionalityNotAvailableComponent,
  TrainingStatusBadgeComponent,
  StartingGuideComponent,
  CreazioneComponent,
  AddestramentoComponent,
  UtilizzoComponent,
  ChatConfigurationSidebarComponent,
  RecapComponent,
  MarketplaceItemCardComponent,
  AiModelSelectComponent,
  LanguageDropdownComponent,
  AssistantChatButtonsComponent,
  ApiKeyTableComponent,
  SourceTagsListComponent,
  ShareComponent,
  ShareModalWrapperComponent,
  AccessRecapComponent,
  PricingCardComponent,
  SelectAgencyModalComponent,
  AssistantCardComponent,
];

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  PrimeNgModule,
  NgxFileDragDropModule,
  CoreModule,
  RouterModule,
  TranslateModule,
  InfiniteScrollDirective,
  LMarkdownEditorModule,
  AngularMarkdownEditorModule,
  MatIconModule,
  NgxPhotoEditorModule,
];

const DIRECTIVES = [
  MaskInputDirective,
  BackButtonDirective,
  AppendToBodyDirective,
  BlockCopyPasteDirective,
  HideInSegmentDirective,
  CtrlEnterNewlineDirective,
  HideIfNoBotDirective,
  HighightWordDirective,
  GlueLabsEnabledDirective,
  StripHtmlDirective,
  HideIfMarketplaceDirective,
  FallbackImageDirective,
  ResponsiveColumnsDirective,
  GalleryLayoutDirective,
  ProfileSettingAllowedDirective,
  AssistantBackgroundDirective,
];

const PROVIDERS = [DialogService, MessageService, ConfirmationService, ChatService];

const PIPES = [
  TruncatePipe,
  DateTimePipe,
  TrainingStatusPipe,
  ConversationSourcePipe,
  MarkdownParserPipe,
  AvailableLinkPipe,
  PresetsPipe,
  SortKeyvaluePipe,
  DatestringToDatePipe,
  RecapWizardHeaderPipe,
  CrawlingMethodPipe,
  LastMessagePipe,
  CompanyLevelPipe,
  FilterMarketplacePipe,
  LanguagePipe,
  SmartTruncatePipe,
  KeepSvgAsTextPipe,
  MarketplaceUsernameParserPipe,
];

/**
 * Importa tutti i componenti riutilizzabili all’interno dell’applicazione.
 */
@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES, ...MODALS, ...ASSISTANT_CREATION_COMPONENTS, ...LAYOUT_COMPONENTS],
  imports: [CommonModule, ...MODULES],
  providers: [...PROVIDERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [...COMPONENTS, ...MODULES, ...DIRECTIVES, ...PIPES, ...ASSISTANT_CREATION_COMPONENTS, ...LAYOUT_COMPONENTS],
})
export class SharedModule {}
