import { Component, OnDestroy } from '@angular/core';
import { intersection, uniqBy } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Observable, combineLatest, finalize, iif } from 'rxjs';
import { DataSourcesService } from 'src/app/main/services/data-sources.service';
import { DataVisibilityService } from 'src/app/main/services/data-visibility.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { TagsService } from 'src/app/main/services/tags.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { ACCESS_TYPES } from '../../constants/data-sources.consts';
import { ChatBotSource, IUpdateDataSourceManyDTO } from '../../models/chat-bot-source.model';
import { DataVisibility } from '../../models/data-visibility.model';
import { KbTag } from '../../models/kb-tags.model';
import { CompanyGroup, CompanyGroupUser } from '../../models/users-and-groups.model';
import { ManageSourcePermissionsModalComponent } from '../manage-source-permissions-modal/manage-source-permissions-modal.component';

@Component({
  selector: 'app-massive-kb-actions-modal',
  templateUrl: './massive-kb-actions-modal.component.html',
  styleUrl: './massive-kb-actions-modal.component.scss',
})
export class MassiveKBActionsModalComponent implements OnDestroy {
  readonly today = new Date();
  readonly tabMenuItems: MenuItem[] = [
    {
      tabindex: 'tags',
      label: 'private.knowledgePage.massiveTagsActionsModal.tabs.tags',
    },
    {
      tabindex: 'access',
      label: 'private.knowledgePage.massiveTagsActionsModal.tabs.access',
    },
    {
      tabindex: 'validity',
      label: 'private.knowledgePage.massiveTagsActionsModal.tabs.validity',
    },
  ];
  readonly accessTypes = ACCESS_TYPES;
  activeMenuItem = this.tabMenuItems[0];

  validityPeriod: {
    from: Date | null;
    to: Date | null;
  } = {
    from: null,
    to: null,
  };

  minValidityPeriods: {
    from: Date | null;
    to: Date | null;
  } = {
    from: null,
    to: null,
  };

  botId: string;
  sources: ChatBotSource[];
  allTags: KbTag[] = [];
  commonTags: KbTag[] = [];
  currentTags: KbTag[] = [];

  visibilities: DataVisibility[] = [];

  loading: boolean = false;
  updated: boolean = false;
  updating: boolean = false;
  isDeletingAllVisibilities: boolean = false;

  accessType: 'PUBLIC' | 'PRIVATE' = 'PUBLIC';

  massiveActionPayload: IUpdateDataSourceManyDTO = {
    dataSourceIds: [],
    visibilities: {
      groupIds: [],
      userIds: [],
    },
    validityFrom: undefined,
    validityTo: undefined,
  };

  constructor(
    public readonly ref: DynamicDialogRef,
    private readonly tagsService: TagsService,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly dialogService: DialogService,
    private readonly dataSourcesService: DataSourcesService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly confirmationService: ConfirmationService,
    private readonly dataVisibilityService: DataVisibilityService
  ) {
    this.botId = this.config.data.botId;
    this.sources = this.config.data.dataSources;
    this.massiveActionPayload.dataSourceIds = this.sources.map((s) => s.id);

    this.accessType = this.sources.flatMap((s) => s.dataVisibilities).length ? 'PRIVATE' : 'PUBLIC';
    this.getBotTags();
  }

  ngOnDestroy(): void {
    this.ref.close({ updated: this.updated });
  }

  getBotTags(tag?: KbTag, deleted?: boolean) {
    this.loading = true;
    this.tagsService
      .getAllTagsForBot$(this.botId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (tags) => {
          this.allTags = uniqBy(tags, 'label');
          if (!this.currentTags.length) this.currentTags = this.getCommonTags();
          if (tag) {
            if (!deleted) {
              if (!this.currentTags.find((t) => t.id === tag.id)) {
                this.currentTags.push(tag);
              }
            } else
              this.currentTags.splice(
                this.currentTags.findIndex((v) => v.id === tag.id),
                1
              );
            this.updated = true;
          }
          this.commonTags = intersection(tags, this.currentTags);
        },
      });
  }

  private getCommonTags(): KbTag[] {
    return this.sources
      .map((s) => s.tags)
      .reduce((elementiComuni, arrayCorrente) => {
        const idSet = new Set(arrayCorrente.map((obj) => obj.id));
        return elementiComuni.filter((obj) => idSet.has(obj.id));
      });
  }

  sameValue(dv1: DataVisibility, dv2: DataVisibility) {
    return dv1.user?.id === dv2.user?.id && dv1.group?.id === dv2.group?.id;
  }

  onChange(event: MultiSelectChangeEvent) {
    iif(
      () => !!(event.value as KbTag[]).find((t) => t.id === event.itemValue.id),
      this.tagsService.associateTag$(this.botId, event.itemValue.id, this.massiveActionPayload.dataSourceIds),
      this.tagsService.deAssociateTag$(this.botId, event.itemValue.id, this.massiveActionPayload.dataSourceIds)
    ).subscribe({
      next: () => {
        this.updated = true;

        this.getBotTags(event.itemValue);
      },
    });
  }

  addMassive() {
    this.updating = true;
    this.dataSourcesService
      .updateDataSourceMany(this.botId, this.massiveActionPayload)
      .pipe(finalize(() => (this.updating = false)))
      .subscribe({
        next: () => {
          this.updated = true;
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.knowledgePage.massiveTagsActionsModal.massiveUpdated'),
          });
          this.ref.close({ updated: this.updated });
        },
        error: ({ error }) => {
          this.toastService.addError({
            summary: this.mainLanguageService.instant('common.error'),
            detail: error.detail,
          });
        },
      });
  }

  getAccessType(value: 'PUBLIC' | 'PRIVATE') {
    return this.accessTypes.find((a) => a.value === value)!;
  }

  handleAccessButtonClick(event: MouseEvent, clickedValue: 'PUBLIC' | 'PRIVATE') {
    event.preventDefault();

    if (clickedValue === 'PUBLIC' && this.accessType === 'PRIVATE' && this.sources.flatMap((s) => s.dataVisibilities).length) {
      this.confirmationService.confirm({
        header: this.mainLanguageService.instant('common.warning'),
        message: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.changeTypeAlert'),
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.isDeletingAllVisibilities = true;
          this.deleteAllGroups(clickedValue);
        },
      });
    } else {
      this.accessType = clickedValue;
    }
  }

  private deleteAllGroups(clickedValue: 'PUBLIC' | 'PRIVATE') {
    const calls: Observable<any>[] = [];
    this.sources.forEach((s) => s.dataVisibilities?.forEach((dv) => calls.push(this.dataVisibilityService.deleteVisibility$(s.id, dv.id))));
    combineLatest(calls)
      .pipe(
        finalize(() => {
          this.accessType = clickedValue;
          this.isDeletingAllVisibilities = false;
          this.updated = true;
        })
      )
      .subscribe();
  }

  openPermissionModal() {
    this.dialogService
      .open(ManageSourcePermissionsModalComponent, {
        showHeader: false,
        styleClass: 'md:w-6 w-full massive-dialog',
        header: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.header'),
        data: {
          addWithAPI: false,
        },
      })
      .onClose.subscribe({
        next: (v?: { userIds: CompanyGroupUser[]; groupIds: CompanyGroup[] }) => {
          if (v) {
            if (v.userIds && v.groupIds) {
              this.massiveActionPayload.visibilities.groupIds = v.groupIds.map((g) => g.id);
              this.massiveActionPayload.visibilities.userIds = v.userIds.map((u) => u.id);
              this.visibilities = [
                ...this.visibilities,
                ...v.userIds.map((u) => new DataVisibility({ user: u, group: {} })),
                ...v.groupIds.map((g) => new DataVisibility({ group: g, user: {} })),
              ];
            }

            this.updated = true;
          }
        },
      });
  }

  deleteVisibility(id: string, visibilityId: string) {
    this.updated = true;
    this.visibilities.splice(
      this.visibilities.findIndex((v) => v.id === visibilityId),
      1
    );
  }

  getVisibilityId(id: string, source: ChatBotSource) {
    const type = source.dataVisibilities?.find((v) => v.group.id === id) ? 'group' : 'user';
    return type === 'group'
      ? source.dataVisibilities?.find((v) => v.group.id === id)?.id!
      : source.dataVisibilities?.find((v) => v.user.id === id)?.id!;
  }

  get isButtonDisabled() {
    return (
      (!this.massiveActionPayload.validityFrom || !this.massiveActionPayload.validityTo) &&
      !this.massiveActionPayload.visibilities.groupIds.length &&
      !this.massiveActionPayload.visibilities.userIds.length &&
      !this.currentTags.length
    );
  }

  updateValidityPeriod() {
    console.log(this.massiveActionPayload);
    if (this.massiveActionPayload.validityFrom) {
      this.minValidityPeriods.from = new Date(this.massiveActionPayload.validityFrom);
    } else {
      this.minValidityPeriods.from = null;
    }

    if (this.massiveActionPayload.validityTo) {
      this.minValidityPeriods.to = new Date(this.massiveActionPayload.validityTo);
    } else {
      this.minValidityPeriods.to = null;
    }
  }
}
