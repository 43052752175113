import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, PrimeIcons } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, finalize, iif } from 'rxjs';
import { DataVisibilityService } from 'src/app/main/services/data-visibility.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { UsersAndGroupsService } from 'src/app/main/services/users-and-groups.service';
import { ChatBotSource } from '../../models/chat-bot-source.model';
import { CompanyGroup, CompanyGroupUser } from '../../models/users-and-groups.model';

@Component({
  selector: 'app-manage-source-permissions-modal',
  templateUrl: './manage-source-permissions-modal.component.html',
  styleUrl: './manage-source-permissions-modal.component.scss',
})
export class ManageSourcePermissionsModalComponent implements OnInit {
  readonly tabMenuItems: MenuItem[] = [
    {
      tabindex: 'user',
      label: 'private.sourceDetailsPage.createAccess.modal.addUser',
      icon: 'person_add',
    },
    {
      tabindex: 'group',
      label: 'private.sourceDetailsPage.createAccess.modal.addGroup',
      icon: 'group_add',
    },
  ];
  activeMenuItem = this.tabMenuItems[0];
  addWithAPI: boolean = true;

  source: ChatBotSource;
  isCreating: boolean = false;
  isDeleting: boolean = false;

  addedAccess: 'group' | 'user' = 'user';

  users: CompanyGroupUser[] = [];
  groups: CompanyGroup[] = [];

  group: CompanyGroup[] = [];
  user: CompanyGroupUser[] = [];

  constructor(
    public readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly confirmationService: ConfirmationService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly dataVisibilityService: DataVisibilityService,
    private readonly usersAndGroupsService: UsersAndGroupsService
  ) {
    this.source = this.config.data.source;
    if (this.config.data.addWithAPI !== undefined) this.addWithAPI = this.config.data.addWithAPI;
  }

  ngOnInit(): void {
    this.getUsersAndGroups();
  }

  onActiveItemChange() {
    this.changeAddingType(this.activeMenuItem.tabindex! as 'group' | 'user');
  }

  getUsersAndGroups() {
    combineLatest({ users: this.usersAndGroupsService.getUsers$(0, 1000, ''), groups: this.usersAndGroupsService.getGroups$(0, 1000, '') }).subscribe(
      {
        next: ({ users, groups }) => {
          this.users = users.items;
          this.groups = groups.items;
        },
      }
    );
  }

  addVisibility() {
    this.isCreating = true;
    if (this.addWithAPI) {
      this.getVisibilityObservable()
        .pipe(finalize(() => (this.isCreating = false)))
        .subscribe({
          next: (v) => {
            this.ref.close({ created: true });
          },
        });
    } else {
      this.ref.close({ userIds: this.user, groupIds: this.group });
    }
  }

  deleteAllVisibilities() {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.modal.deleteMessage'),
      acceptLabel: this.mainLanguageService.instant('common.delete'),
      rejectLabel: this.mainLanguageService.instant('common.keep'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined',
      acceptIcon: PrimeIcons.TRASH,
      rejectIcon: PrimeIcons.TIMES,
      accept: () => this.deleteAll(),
    });
  }

  deleteAll() {
    if (!Array.isArray(this.source) && this.source.dataVisibilities) {
      this.isDeleting = true;
      const calls = this.source.dataVisibilities.map((v) => this.dataVisibilityService.deleteVisibility$((this.source as ChatBotSource).id, v.id));

      combineLatest(calls)
        .pipe(finalize(() => (this.isDeleting = false)))
        .subscribe({
          next: () => this.ref.close({ created: true }),
        });
    }
  }

  private getVisibilityObservable() {
    return iif(
      () => this.addedAccess === 'group',
      combineLatest(
        this.group.map((group) =>
          this.dataVisibilityService.createVisibility$((this.source as ChatBotSource).id, { enabled: true, groupId: group?.id })
        )
      ),
      combineLatest(
        this.user.map((user) => this.dataVisibilityService.createVisibility$((this.source as ChatBotSource).id, { enabled: true, userId: user?.id }))
      )
    );
  }

  changeAddingType(value: 'group' | 'user') {
    this.group = [];
    this.user = [];

    this.addedAccess = value;
  }

  get buttonDisabled() {
    return (this.addedAccess === 'group' && !this.group) || (this.addedAccess === 'user' && !this.user);
  }

  get removeAllButtonDisabled() {
    return !!!this.source?.dataVisibilities?.length;
  }
}
