<div class="flex flex-column gap-3">
  <p>{{ 'private.updatePwaModal.body' | translate }}</p>
  <div class="flex flex-row gap-3 justify-content-end">
    <button pButton pRipple class="p-button-outlined p-button-danger" (click)="remindMeLater()">
      <span class="flex flex-row align-items-center gap-2">
        <custom-icon [iconName]="'close'"></custom-icon>
        <p>{{ 'private.updatePwaModal.remindMeLater' | translate }}</p>
      </span>
    </button>
    <button pButton pRipple class="p-button-primary" (click)="updatePwa()">
      <span class="flex flex-row align-items-center gap-2">
        <custom-icon [iconName]="'upgrade'"></custom-icon>
        <p>{{ 'private.updatePwaModal.update' | translate }}</p>
      </span>
    </button>
  </div>
</div>
