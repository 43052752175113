import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-models-table-modal',
  templateUrl: './ai-models-table-modal.component.html',
  styleUrl: './ai-models-table-modal.component.scss'
})
export class AiModelsTableModalComponent {

}
