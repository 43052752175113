import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { DataSourcesService } from 'src/app/main/services/data-sources.service';
import { SettingsService } from 'src/app/main/services/settings.service';
import { Settings } from '../../enums/settings.enum';

@Component({
  selector: 'app-kb-settings-modal',
  templateUrl: './kb-settings-modal.component.html',
  styleUrl: './kb-settings-modal.component.scss',
})
export class KbSettingsModalComponent implements OnDestroy {
  readonly Settings = Settings;
  knowledgeForm: FormGroup;
  hasUpdatedSettings: boolean = false;

  idBot: string = '';

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly settingsService: SettingsService,
    private readonly dataSourceService: DataSourcesService
  ) {
    this.idBot = this.config.data.idBot;

    this.knowledgeForm = this.fb.group<{ [key in Partial<Settings>]?: AbstractControl }>({
      ASSISTANT_SHOW_KB_MATCH: new FormControl(false),
      ASSISTANT_SHOW_KB_RESOURCES: new FormControl(false),
      ASSISTANT_KB_RESOURCES_DOWNLOAD: new FormControl(false),
    });

    this.getSettings();
  }

  getSettings() {
    this.dataSourceService.getSettings(this.idBot).subscribe({
      next: (v) => {
        const values = Object.entries(v);
        values.forEach(([key, { enable }]) => this.knowledgeForm.patchValue({ [key]: enable }));
        this.getIsDisabled();
      },
    });
  }

  ngOnDestroy(): void {
    this.ref.close({ updated: this.hasUpdatedSettings });
  }

  getFormControl(formControlName: string) {
    return this.knowledgeForm?.get(formControlName) as FormControl;
  }

  onToggleChange(id: Settings, value: InputSwitchChangeEvent) {
    const body = this.buildBody(id, value.checked);
    this.settingsService.updateMultipleCompanySettingsForAssistant(this.idBot, id, body).subscribe({
      next: () => {
        this.hasUpdatedSettings = true;
        this.getSettings();
      },
    });
  }

  buildBody(setting: Settings, value: boolean) {
    let body: any[] = [];
    switch (setting) {
      case Settings.ASSISTANT_SHOW_KB_MATCH:
        if (!value) {
          body = [
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_SHOW_KB_MATCH,
              value: { enable: value },
            },
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_SHOW_KB_RESOURCES,
              value: { enable: value },
            },
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_KB_RESOURCES_DOWNLOAD,
              value: { enable: value },
            },
          ];
        } else {
          body = [
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_SHOW_KB_MATCH,
              value: { enable: value },
            },
          ];
        }
        break;
      case Settings.ASSISTANT_SHOW_KB_RESOURCES:
        if (!value) {
          body = [
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_SHOW_KB_RESOURCES,
              value: { enable: value },
            },
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_KB_RESOURCES_DOWNLOAD,
              value: { enable: value },
            },
          ];
        } else {
          body = [
            {
              assistantId: this.idBot,
              availableSettingId: Settings.ASSISTANT_SHOW_KB_RESOURCES,
              value: { enable: value },
            },
          ];
        }
        break;
      case Settings.ASSISTANT_KB_RESOURCES_DOWNLOAD:
        body = [
          {
            assistantId: this.idBot,
            availableSettingId: Settings.ASSISTANT_KB_RESOURCES_DOWNLOAD,
            value: { enable: value },
          },
        ];
        break;
    }

    return body;
  }

  getIsDisabled() {
    const rawValue = this.knowledgeForm.getRawValue();

    if (rawValue.ASSISTANT_SHOW_KB_MATCH) this.knowledgeForm.get('ASSISTANT_SHOW_KB_RESOURCES')?.enable();
    else this.knowledgeForm.get('ASSISTANT_SHOW_KB_RESOURCES')?.disable();

    if (rawValue.ASSISTANT_SHOW_KB_MATCH && rawValue.ASSISTANT_SHOW_KB_RESOURCES) this.knowledgeForm.get('ASSISTANT_KB_RESOURCES_DOWNLOAD')?.enable();
    else this.knowledgeForm.get('ASSISTANT_KB_RESOURCES_DOWNLOAD')?.disable();
  }
}
