<div
  class="flex flex-column h-full page-sidebar"
  [@sidebarExpand]="sidebarState"
  [ngClass]="{ 'sidebar-collapsed': !isExpanded, 'sidebar-expanded': isExpanded }"
>
  <div class="flex p-3 pt-5" [ngClass]="{ 'justify-content-between': isExpanded, 'justify-content-center': !isExpanded }">
    @if (isExpanded) {
    <p class="font-semibold mb-0">{{ 'private.historyPage.filters.title' | translate | uppercase }}</p>
    }
    <custom-icon
      [type]="'material'"
      [iconName]="!isExpanded ? 'left_panel_open' : 'left_panel_close'"
      class="flex cursor-pointer"
      (click)="toggleExpand()"
    ></custom-icon>
  </div>
  @if(isExpanded) {
  <div class="flex-grow-1">
    @if (user && user.company) {
    <span class="w-full" *level="user.company.level">
      <p-selectButton
        [allowEmpty]="false"
        class="select-preset flex flex-column p-3"
        optionValue="value"
        [(ngModel)]="filters.company"
        (ngModelChange)="onGetUsers.emit()"
        [options]="companyOptions"
      >
        <ng-template let-option pTemplate>
          <p class="my-auto font-semibold">{{ option.label | translate }}</p>
        </ng-template>
      </p-selectButton>
    </span>
    } @if(filters.company) {
    <div class="flex flex-column gap-2 p-3">
      <p class="my-0 font-semibold">{{ 'private.analyticsPage.filters.byUser' | translate }}</p>
      <p-multiSelect
        #userMultiselect
        styleClass="w-full"
        [options]="users"
        optionLabel="label"
        optionValue="value"
        display="chip"
        [showClear]="true"
        [(ngModel)]="filters.selectedUsersId"
        [lazy]="true"
        appendTo="body"
      >
        <ng-template let-value pTemplate="selectedItems">
          @for (option of value; track $index) {
          <div class="inline-flex align-items-center gap-2 px-1">
            <p-chip styleClass="py-1 px-2 bg-primary text-white">{{ option.label }}</p-chip>
          </div>
          } @if(!value || value.length === 0) {
          <div>{{ 'private.analyticsPage.filters.selectUser' | translate }}</div>
          }
        </ng-template>
      </p-multiSelect>
    </div>
    <p-divider styleClass="my-0"></p-divider>
    }
    <div class="w-full flex flex-column justify-content-end gap-2 p-3">
      <p class="my-auto font-semibold">{{ 'private.analyticsPage.filters.byDatePreset' | translate }}</p>
      <p-selectButton class="select-preset flex flex-column" [(ngModel)]="filters.presetSelected" [options]="presets" (onChange)="setDate($event)">
        <ng-template let-preset pTemplate>
          <p class="my-auto font-semibold">{{ preset | presets | translate }}</p>
        </ng-template>
      </p-selectButton>
      @if (filters.presetSelected === 'custom') {
      <span class="flex flex-column gap-3">
        <div class="w-full">
          <p-calendar
            [(ngModel)]="filters.dates.startDate"
            [maxDate]="filters.dates.endDate"
            id="startDate"
            appendTo="body"
            styleClass="w-full"
            dateFormat="dd/mm/yy"
          ></p-calendar>
        </div>
        <div class="w-full">
          <p-calendar
            [(ngModel)]="filters.dates.endDate"
            [minDate]="filters.dates.startDate"
            id="endDate"
            appendTo="body"
            styleClass="w-full"
            dateFormat="dd/mm/yy"
          ></p-calendar>
        </div>
      </span>
      }
    </div>
  </div>
  <div class="flex justify-content-between align-items-center p-3">
    <p class="text-accent font-semibold cursor-pointer mb-0 cursor-pointer" (click)="onClearClick()">
      {{ 'private.analyticsPage.filters.clearAll' | translate }}
    </p>
    <button
      pButton
      pRipple
      [label]="'private.analyticsPage.filters.filter' | translate"
      (click)="onFilter.emit(filters)"
      [disabled]="filterButtonDisabled"
    ></button>
  </div>
  }
</div>
