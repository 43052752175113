import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketplaceItem } from '../../models/marketplace.model';
import { ImageUuidService } from 'src/app/main/services/image-uuid.service';
import { AiModelCapabilities } from '../../models/ai-models.model';

@Component({
  selector: 'app-marketplace-item-card',
  templateUrl: './marketplace-item-card.component.html',
  styleUrl: './marketplace-item-card.component.scss',
})
export class MarketplaceItemCardComponent {
  readonly Capabilities = AiModelCapabilities;
  @Input() item!: MarketplaceItem;
  @Output() onItemPriceClick: EventEmitter<MarketplaceItem> = new EventEmitter();
  @Output() onNavigateToChat: EventEmitter<MarketplaceItem> = new EventEmitter();

  constructor(public readonly imageUuidService: ImageUuidService) {}
}
