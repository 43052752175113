import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IMarketplaceFilters } from '../../models/filters.model';
import { ICurrentTagFilters, MarketplaceCategories } from '../../models/marketplace.model';
import { latestAssistantAnim } from '../../animations/latest-assistants-sidebar.anim';
import { mapValues } from 'lodash';
import { debounceTime, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-marketplace-sidebar',
  templateUrl: './marketplace-sidebar.component.html',
  styleUrl: './marketplace-sidebar.component.scss',
  animations: [latestAssistantAnim],
})
export class MarketplaceSidebarComponent {
  @Output() onFilter: EventEmitter<{ filters: IMarketplaceFilters; categories: ICurrentTagFilters }> = new EventEmitter();
  @Output() onClear: EventEmitter<void> = new EventEmitter();
  @Output() onCategoriesChange: EventEmitter<ICurrentTagFilters> = new EventEmitter();

  searchSubject: Subject<string> = new Subject<string>();
  destroySubject: Subject<void> = new Subject<void>();
  sidebarState: 'expanded' | 'collapsed' = 'collapsed';
  isExpanded: boolean = false;

  currentTagFilters: ICurrentTagFilters = {
    using: false,
    official: false,
    legacy: false,
    promo: false,
  };
  selectedCategories: MarketplaceCategories[] = [];

  filtersForm: FormGroup = new FormGroup({
    search: new FormControl(''),
    sortBy: new FormControl(null),
    from: new FormControl(<Date | null>null),
    to: new FormControl(<Date | null>null),
    rating: new FormControl(0),
  });

  constructor() {
    this.searchSubject
      .pipe(
        debounceTime(300),
        takeUntil(this.destroySubject),
        tap((searchTerm) => {
          this.filtersForm.patchValue({ search: searchTerm }, { emitEvent: false });
          this.onFilter.emit({ filters: this.filtersForm.value, categories: this.currentTagFilters });
        })
      )
      .subscribe();

    this.filtersForm.get('search')?.valueChanges.subscribe({
      next: (searchTerm) => {
        this.searchSubject.next(searchTerm);
      },
    });
  }

  toggleExpand() {
    this.sidebarState = this.sidebarState === 'collapsed' ? 'expanded' : 'collapsed';
    this.isExpanded = !this.isExpanded;
  }

  toggleCategory(category: keyof ICurrentTagFilters) {
    Object.entries(this.currentTagFilters).forEach(([key]) => {
      if (key !== category) {
        this.currentTagFilters[key as keyof ICurrentTagFilters] = false;
      }
    });
    this.currentTagFilters[category] = !this.currentTagFilters[category];
    this.onFilter.emit({
      filters: this.filtersForm.value,
      categories: this.currentTagFilters,
    });
  }

  onSubmit() {
    this.onFilter.emit({
      filters: this.filtersForm.value,
      categories: this.currentTagFilters,
    });
  }

  onClearAll() {
    this.filtersForm.reset();
    this.currentTagFilters.legacy = false;
    this.currentTagFilters.official = false;
    this.currentTagFilters.promo = false;
    this.currentTagFilters.using = false;
    this.onClear.emit();
  }
}
