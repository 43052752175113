<div class="flex flex-wrap gap-2">
  <p-chip styleClass="bg-primary text-white cursor-pointer" (click)="onAdd.emit(true)">
    <span class="flex align-items-center px-1 py-2">
      <i class="pi pi-plus-circle"></i>
      <p>{{ 'private.sourceDetailsPage.createAccess.addRule' | translate }}</p>
    </span>
  </p-chip>
  @for (visibility of visibilities; track $index) {
  <p-chip [styleClass]="visibility.user.id ? 'bg-secondary' : 'bg-cyan-300'">
    <span class="flex align-items-center gap-2 px-1 py-2">
      <i [class]="visibility.user.id ? 'pi pi-user' : 'pi pi-users'" class="w-fit"></i>
      <p class="mb-0 text-antialiased">{{ visibility.user.id ? visibility.user.name + ' ' + visibility.user.surname : visibility.group.name }}</p>
      <i class="pi pi-times-circle w-fit cursor-pointer" (click)="deleteVisibility(visibility.id)"></i>
    </span>
  </p-chip>
  }
</div>
