<div class="flex flex-column gap-3 align-items-center justify-content-center relative">
  @if (currentImage) {
  <div class="relative w-4 h-full flex justify-content-center align-items-center">
    <img [src]="currentImage" class="h-full w-full current-image" />
    @if (!isUpdatingImage && currentImage !== previousImage) {
    <span
      class="absolute top-0 right-0 cursor-pointer m-1 p-1 border-circle shadow-1 bg-red-400 text-white"
      [pTooltip]="'private.configurationPage.generalPage.imageModal.delete' | translate"
      (click)="currentImage = previousImage"
    >
      <custom-icon [iconName]="'close'"></custom-icon>
    </span>
    } @if (isUpdatingImage) {
    <div class="w-full absolute top-0 left-0 bg-black-alpha-50 h-full flex justify-content-center align-items-center image-loading-overlay">
      <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
    }
  </div>
  } @else if(isUpdatingImage) {
  <div class="w-4 h-full flex bg-black-alpha-50 justify-content-center align-items-center" style="aspect-ratio: 1">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  } @if (aiGeneratedOptions.length) {
  <div class="flex flex-row justify-content-center gap-2">
    @for (option of aiGeneratedOptions; track option.id) {
    <img
      [src]="option.path"
      class="w-2 cursor-pointer"
      [ngClass]="{ 'selected-image': currentImage === option.path }"
      (click)="currentImage = option.path"
    />
    }
  </div>
  }
</div>
<!-- <div class="flex md:flex-row flex-column justify-content-between w-full pt-5">
  <div class="flex flex-column align-items-center justify-content-center gap-4 flex-grow-1 cursor-pointer main-button" (click)="fileInput.click()">
    <input #fileInput type="file" accept="image/png, image/jpeg, image/jpg, image/webp" class="hidden" (change)="updateImage($event)" />
    <span class="generate-button">
      <custom-icon [iconName]="'photo'"></custom-icon>
    </span>
    <p class="font-semibold text-main">Scegli un'immagine dal tuo dispositivo...</p>
  </div>
  <p-divider layout="vertical"></p-divider>
  <div
    class="flex flex-column align-items-center justify-content-center gap-4 flex-grow-1 cursor-pointer main-button"
    (click)="generateImageWithAI()"
  >
    <span class="generate-button">
      <custom-icon [iconName]="'cognition'"></custom-icon>
    </span>
    <p class="font-semibold text-main">...Oppure genera un'immagine con l'IA</p>
  </div>
</div> -->

<div class="p-dialog-footer p-0 flex justify-content-end mt-5">
  <button pButton [disabled]="currentImage === previousImage" [loading]="loading" [label]="'common.save' | translate" (click)="saveImage()"></button>
</div>
