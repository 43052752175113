import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { Subscription } from 'rxjs';
import { User } from '../models/user.model';

@Pipe({
  name: 'marketplaceUsernameParser',
})
export class MarketplaceUsernameParserPipe implements PipeTransform {
  constructor(private readonly userService: UsersService) {}

  transform(value?: string): string {
    return value?.replaceAll('[USER_NAME]', this.userService.getUserValue()?.name ?? '') ?? '';
  }
}
