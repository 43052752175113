<div class="assistant-card card z-2 w-full p-0 flex flex-column cursor-pointer h-full border-none" (click)="onItemPriceClick.emit(item)">
  <div class="relative w-full flex p-3 info-span justify-content-between align-items-end" appAssistantBackground>
    <img class="z-2 absolute bottom-0 left-0 w-full img-filter" src="/assets/imgs/inset-shadow.png" />
    <img
      fallbackImage
      [attr.data-id]="item.id"
      class="bot-image cursor-pointer z-3 assistant-icon"
      crossorigin="anonymous"
      [src]="imageUuidService.getMarketplaceImageUrlFromUUID(item, 'H')"
    />
    <span class="flex flex-column align-items-end z-3">
      <p class="text-white my-0 font-semibold text-lg">{{ item.rating?.rating }}</p>
      <p-rating [ngModel]="item.rating?.rating" [cancel]="false" class="marketplace-rating" [readonly]="true"></p-rating>
    </span>
  </div>
  <div class="p-3 flex flex-column flex-grow-1 justify-content-between">
    <span>
      <p class="my-0 text-lg font-semibold">{{ item.name }}</p>
      <p class="my-0 font-light text-accent-dark">{{ '@' + item.creator?.name }}</p>
    </span>
    <p class="my-4 font-light text-base-color flex-grow-1">
      {{ item.storeCard?.longDescription || item.storeCard?.description || item.description | truncate : 200 }}
    </p>
    <p-divider styleClass="mt-1"></p-divider>
    <div class="flex flex-row align-items-center gap-2">
      <p class="my-0 font-semibold text-base-color">{{ 'private.configurationPage.assistantPage.capabilities' | translate }}:</p>
      <span class="flex align-items-center gap-1 text-base-color">
        @if (item.capabilities.includes(Capabilities.TEXT)) {
        <custom-icon
          [iconName]="'subject'"
          class="flex"
          [class]="'quick-action-icon capability-icon'"
          [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.text' | translate"
          tooltipPosition="top"
        ></custom-icon>
        <custom-icon
          [iconName]="'attach_file'"
          class="flex"
          [class]="'quick-action-icon capability-icon'"
          [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.attachments' | translate"
          tooltipPosition="top"
        ></custom-icon>
        } @if (item.capabilities.includes(Capabilities.VIDEO)) {
        <custom-icon
          [iconName]="'videocam'"
          class="flex"
          [class]="'quick-action-icon capability-icon'"
          [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.video' | translate"
          tooltipPosition="top"
        ></custom-icon>
        } @if (item.capabilities.includes(Capabilities.AUDIO)) {
        <custom-icon
          [iconName]="'volume_up'"
          class="flex"
          [class]="'quick-action-icon capability-icon'"
          [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.audio' | translate"
          tooltipPosition="top"
        ></custom-icon>
        } @if (item.capabilities.includes(Capabilities.IMAGE)) {
        <custom-icon
          [iconName]="'image'"
          class="flex"
          [class]="'quick-action-icon capability-icon'"
          [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.image' | translate"
          tooltipPosition="top"
        ></custom-icon>
        } @if(item.feature?.urlRetrieval) {
        <custom-icon
          [iconName]="'add_link'"
          class="flex"
          [class]="'quick-action-icon capability-icon'"
          [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.links' | translate"
          tooltipPosition="top"
        ></custom-icon>
        }
      </span>
    </div>
    <p-divider></p-divider>
    <span class="flex sm:flex-row flex-column justify-content-between">
      <span class="sm:col-6 col-12 p-0">
        <p class="my-0 text-gray-400">{{ 'private.dashboardPage.llmModel' | translate }}</p>
        <p class="my-0 text-lg font-semibold">{{ '-' }}</p>
      </span>
      <span class="sm:col-6 col-12 p-0 sm:text-right sm:pt-0 pt-3">
        <p class="my-0 text-gray-400">{{ 'private.dashboardPage.price' | translate }}</p>
        <span class="flex gap-2 sm:justify-content-end justify-content-start">
          <custom-icon [iconName]="'poker_chip'" [class]="'text-sm'"></custom-icon>
          <p class="my-0 text-lg font-semibold">{{ item.cost?.message | number : '1.0-2' }}{{ 'private.dashboardPage.perMessage' | translate }}</p>
        </span>
      </span>
    </span>
    <p-divider></p-divider>
    <button
      pButton
      [label]="
        item.using
          ? ('private.dashboardPage.marketplaceSection.alreadyUsing' | translate)
          : ('private.dashboardPage.marketplaceSection.use' | translate)
      "
      (click)="$event.stopPropagation(); onNavigateToChat.emit(item)"
      class="mt-auto"
    ></button>
  </div>
</div>
