<p-confirmDialog></p-confirmDialog>

<div
  class="flex flex-column h-full page-sidebar"
  [@sidebarExpand]="sidebarState"
  [ngClass]="{ 'sidebar-collapsed': !isExpanded, 'sidebar-expanded': isExpanded }"
>
  <div class="flex p-5" [ngClass]="{ 'justify-content-between': isExpanded, 'justify-content-center': !isExpanded }">
    @if(isExpanded) {
    <p class="mb-0 font-semibold">{{ 'private.knowledgePage.filter' | translate | uppercase }}</p>
    }
    <custom-icon
      [type]="'material'"
      [iconName]="!isExpanded ? 'left_panel_open' : 'left_panel_close'"
      class="flex cursor-pointer"
      (click)="toggleExpand()"
    ></custom-icon>
  </div>
  <form [formGroup]="filtersForm" [ngClass]="{ 'opacity-0': !isExpanded, 'opacity-100': isExpanded }" class="flex-grow-1 flex flex-column">
    <span class="flex-1">
      <div class="searchbar px-4 py-3">
        <p-iconField>
          <p-inputIcon styleClass="pi pi-search"></p-inputIcon>
          <input
            pInputText
            class="w-full bg-transparent border-none"
            formControlName="search"
            [placeholder]="'private.historyPage.filters.search' | translate"
          />
        </p-iconField>
      </div>
      <p-divider styleClass="my-0"></p-divider>
      <div class="p-3">
        <p class="">{{ 'private.knowledgePage.filters.tags.label' | translate }}</p>
        <div class="flex flex-row align-items-center gap-2 flex-wrap mt-4">
          @for (tag of availableTags | slice:0:!showAllTags && availableTags.length > 3 ? 3 : undefined; track $index) {
          <p-tag
            class="source-tag cursor-pointer flex"
            (click)="toggleTag(tag)"
            [style]="{ background: '#' + tag.color + (!getIsTagInFilter(tag) ? '50' : '') }"
          >
            <span class="flex gap-2 align-items-center">
              <p class="my-0" style="font-size: 0.6rem">{{ tag.label | translate }}</p>
            </span>
          </p-tag>
          } @if (availableTags.length > 3 && !showAllTags) {
          <div
            class="bg-gray-300 hover:bg-gray-500 transition-all transition-duration-200 flex justify-content-center align-items-center py-1 px-2 cursor-pointer border-round-2xl"
            (click)="showAllTags = !showAllTags"
          >
            <i class="pi pi-ellipsis-h text-gray-700 hover:text-white transition-all transition-duration-200 flex justify-content-center"></i>
          </div>
          }
        </div>
      </div>
    </span>
  </form>
  <p-divider styleClass="my-0"></p-divider>
</div>
