import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '../../models/user.model';
import { UsersService } from 'src/app/main/services/users.service';
import { Subscription, take } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { IHistoryFilters } from '../../models/filters.model';
import { latestAssistantAnim } from '../../animations/latest-assistants-sidebar.anim';

@Component({
  selector: 'app-history-sidebar',
  templateUrl: './history-sidebar.component.html',
  styleUrl: './history-sidebar.component.scss',
  animations: [latestAssistantAnim],
})
export class HistorySidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filtersOpen: boolean = false;
  @Output() onFilter: EventEmitter<{ filters: Partial<IHistoryFilters>; cleared?: boolean }> = new EventEmitter();
  @Output() onExpandChange: EventEmitter<boolean> = new EventEmitter();
  readonly companyOptions = [
    {
      label: 'private.historyPage.filters.companyOptions.yours',
      value: true,
    },
    {
      label: 'private.historyPage.filters.companyOptions.company',
      value: false,
    },
  ];
  user?: User;
  userSubscription?: Subscription;

  sidebarState: 'expanded' | 'collapsed' = 'collapsed';
  isExpanded: boolean = false;

  filterForm: FormGroup = new FormGroup({
    search: new FormControl(''),
    sortBy: new FormControl(null),
    from: new FormControl(<Date | null>null),
    to: new FormControl(<Date | null>null),
    rating: new FormControl(0),
    own: new FormControl(true),
  });

  constructor(private readonly usersService: UsersService) {}

  ngOnInit(): void {
    this.userSubscription = this.usersService
      .getUser$()
      .pipe(take(1))
      .subscribe({
        next: (user) => (this.user = user),
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filtersOpen']) {
      this.sidebarState = this.filtersOpen ? 'expanded' : 'collapsed';
      this.isExpanded = this.filtersOpen;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  onSubmit(cleared: boolean = false) {
    const filtersRaw = this.filterForm.getRawValue();
    const filters: Partial<IHistoryFilters> = {
      ...filtersRaw,
    };

    this.onFilter.emit({ filters, cleared });
  }

  toggleExpand() {
    this.sidebarState = this.sidebarState === 'expanded' ? 'collapsed' : 'expanded';
    this.isExpanded = !this.isExpanded;
    this.onExpandChange.emit(this.isExpanded);
  }

  onClearClick() {
    this.filterForm.reset();
    this.onSubmit(true);
  }

  get isButtonEnabled() {
    return this.filterForm.dirty;
  }
}
