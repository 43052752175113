import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseInputElement, IBaseInputElement } from '../../models/input-element.model';
import { InputElementType } from '../../types/input-element.type';

@Component({
  selector: 'base-input-element',
  templateUrl: './base-input-element.component.html',
  styleUrls: ['./base-input-element.component.scss'],
})
export class BaseInputElementComponent {
  readonly FULL_WIDTH_ELEMENTS: InputElementType[] = ['dropdown', 'input', 'select-button', 'textarea', 'radio', 'checkbox'];
  readonly HIDE_DESCRIPTION_ELEMENTS: InputElementType[] = ['checkbox'];

  @Input() customTextClass: boolean = false;
  @Input() parentForm: FormGroup = new FormGroup({});
  @Input() element: IBaseInputElement = new BaseInputElement();
  @Input() formControl: FormControl = new FormControl();
  @Input() placeholder: string = '';

  @Output() onValueChange: EventEmitter<any> = new EventEmitter();
  @Output() onConfigButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onImageValueChanged: EventEmitter<any> = new EventEmitter();

  emitClick() {
    this.onConfigButtonClick?.emit();
  }

  getValue() {
    return this.formControl.value;
  }

  getImgUrl() {
    if (this.element) return this.formControl.value as string;
    else return '';
  }

  async updateImage(event: any) {
    const reader = new FileReader();

    const file = event.target.files[0] as File;
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formControl.patchValue(reader.result);
        this.onImageValueChanged.emit();
      };
    }
  }

  getFormControlValue() {
    return this.formControl.value;
  }
}
