import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { IPipeline, Pipeline } from '../shared/models/pipelines.model';
import { map, Subject, take } from 'rxjs';
import { SSE, SSEHeaders } from 'sse.js';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';
import { IPipelineLayer } from '../shared/models/pipelines.model';

@Injectable({
  providedIn: 'root',
})
export class PipelinesService {
  constructor(
    private readonly apiService: ApiService,
    private readonly usersService: UsersService
  ) { }

  getPipelines$() {
    return this.apiService.get<IPipeline[]>('manage/pipelines').pipe(map((pipelines) => pipelines.map((pipeline) => new Pipeline(pipeline))));
  }

  getPipelineById$(id: string) {
    return this.apiService.get<IPipeline | any>(`manage/pipelines/${id}`).pipe(
      //@todo: IVAN WTF!!!! QUESTA NON è LA MIA IDEA DI IMPLEMENTA VELOCEMENTE
      //ANCHE PERCHE NON TI HO NEMMENO PASSATO IL FORMATO E QUESTO E' SBAGLIATO
      //map((pipeline) => new Pipeline(pipeline))
    );
  }

  createPipeline$(pipelineName: string) {
    return this.apiService.post<{ id: string }>('manage/pipelines', { name: pipelineName, config: {} }).pipe(map(({ id }) => id));
  }

  editPipeline$(id: string, pipeline: Partial<Pipeline>) {
    return this.apiService.patch(`manage/pipelines/${id}`, pipeline);
  }

  deletePipeline$(id: string) {
    return this.apiService.delete(`manage/pipelines/${id}`);
  }

  async testPipeline$(id: string | number, request: string) {
    const token: any = await this.usersService.getToken();
    const headers = {
      Authorization: `Bearer ${token.accessToken}`,
      'Content-Type': 'application/json'
    };
    const payload = JSON.stringify({
      request,
    });
    return this.doSendSSE(`${environment.BASE_URL}/pipelines/${id}/queries?sse=true`, headers, payload);

  }

  private doSendSSE(url: string, headers: SSEHeaders, payload: any) {
    const response$ = new Subject();
    const source = new SSE(url,
      {
        headers,
        payload: payload,
        debug: true,
      });
    //source.stream();


    source.addEventListener('message', (message: any) => {
      const payload = JSON.parse(message.data);
      response$.next(payload);
    });


    /**/
    source.addEventListener('readystatechange', (e: any) => {
      //INITIALIZING: -1, CONNECTING: 0, OPEN: 1, CLOSED: 2
      if (e.readyState == 2) {
        console.log('closed');
        response$.complete();
      }
    });

    source.addEventListener('error', (e: any) => {
      console.error('error ', e);
      response$.error(e);
    });
    /**/
    source.addEventListener('abort', (e: any) => {
      console.log('abort ', e);
      response$.complete();
    });

    return response$.asObservable();
  }

  formatPipelineData(pipelineData: any): any[] {
    const formattedLevels: IPipelineLayer[] = [];
    if (pipelineData && pipelineData.config && Array.isArray(pipelineData.config.layers)) {
      pipelineData.config.layers.forEach((layer: any, index: number) => {
        const level: IPipelineLayer = {
          layerId: layer.layerId ,
          name: `Level ${layer.layerId || index + 1}`,
          isEditing: false,
          actors: [],
          timeout: layer.timeout || 0,
          message: layer.message || '',
          outputTarget: layer.outputTarget
        };

        if (Array.isArray(layer.actors)) {
          level.actors = layer.actors.map((actorData: any) => ({
            actorId: actorData.actorId.toString(),
            name: actorData.name || 'Unknown',
            type: actorData.type || 'Unknown',
            configuration: actorData.configuration || {}
          }));
        }
        formattedLevels.push(level);
      });
    }
    console.log(formattedLevels);
    return formattedLevels;
  }
}
