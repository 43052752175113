export interface IPipeline {
  layers: IPipelineLayer[];
  inputFormat: 'string' | 'json' | 'image';
  outputFormat: 'string' | 'json' | 'image';
  id: string;
  name: string;
  config: IPipelineConfig;
}
export interface IPipelineLayer {
  layerId: number | string;
  actors: (IPipelineActorAssistant | IPipelineActorRemoteCall | IPipelineActorRouter)[];
  outputTarget?: number[];
  timeout: number;
  message?: string;
  name: string;
  isEditing: boolean;
}
export interface IPipelineActor {
  actorId: string | number;
  name: string;
  type: 'ASSISTANT' | 'LLM' | 'IMAGE_GENERATION' | 'REMOTE_CALL' | 'ROUTER';
  timeout?: number; // Max Timeout for the actor
}

export interface IPipelineActorAssistant extends IPipelineActor {
  type: 'ASSISTANT';
  configuration: IPipelineAssistantConfiguration;
}
export interface IPipelineActorLLM extends IPipelineActor {
  type: 'LLM';
  configuration: IPipelineLLMConfiguration;
}
export interface IPipelineActorImageGeneration extends IPipelineActor {
  type: 'IMAGE_GENERATION';
  configuration: IPipelineImageGenerationConfiguration;
}
export interface IPipelineActorRemoteCall extends IPipelineActor {
  type: 'REMOTE_CALL';
  configuration: IPipelineRemoteCallConfiguration;
}
export interface IPipelineActorRouter extends IPipelineActor {
  type: 'REMOTE_CALL';
  configuration: IPipelineRouterConfiguration;
}

export interface IPipelineAssistantConfiguration {
  assistantId: number | string;
  functioning: 'PROMPT' | 'USER_QUERY' | 'LEVEL_INPUT';
  prompt?: string;//need it when functionin is prompt
  outputLimit?: number; //Limite dell'output se previsto
}
export interface IPipelineLLMConfiguration {
  vendorId: number | string;
  modelId: number | string;
  functioning: 'PROMPT' | 'USER_QUERY' | 'LEVEL_INPUT';
  prompt?: string;//need it when functionin is prompt
  outputLimit?: number; //Limite dell'output se previsto
}
export interface IPipelineImageGenerationConfiguration {
  vendorId: number | string;
  modelId: number | string;
  functioning: 'PROMPT' | 'USER_QUERY' | 'LEVEL_INPUT';
  prompt?: string;//need it when functionin is prompt
  outputLimit?: number; //Limite dell'output se previsto
}


export interface IPipelineRemoteCallConfiguration {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  customHeaders: {
    key: string;
    value: string;
  }[];
  authentication: {
    type: 'BASIC' | 'BEARER' | 'API_KEY' ;
    value: string;
  };
  functioning: 'PROMPT' | 'USER_QUERY' | 'LEVEL_INPUT';
  prompt?: string;//need it when functionin is prompt 
}

export interface IPipelineRouterConfiguration {
  //..definire in maniera sensata
  functioning: 'PROMPT' | 'USER_QUERY' | 'LEVEL_INPUT';
  prompt?: string;//need it when functionin is prompt
}

export class PipelineLayer {
  layerId: number |string = '0';
  timeout: number = 0;
  outputTarget: number[] = [];
  actors: IPipelineActor[] = [];

  constructor(res?: IPipelineLayer) {
    if (res) {
      this.layerId = res.layerId;
      this.timeout = res.timeout;
      this.outputTarget = res.outputTarget || [];
      if (res.actors?.length) this.actors = res.actors as IPipelineActor[];
    }
  }
}
export interface IPipelineConfig {
  inputFormat: string;
  outputFormat: string;
  layers?: IPipelineLayer[];
}
export class PipelineConfig {
  inputFormat: string = '';
  outputFormat: string = '';
  layers: PipelineLayer[] = [];

  constructor(res?: IPipelineConfig) {
    //@todo: IVAN WTF!!!! QUESTA NON è LA MIA IDEA DI IMPLEMENTA VELOCEMENTE
    //ANCHE PERCHE NON TI HO NEMMENO PASSATO IL FORMATO E QUESTO E' SBAGLIATO
    if (res) {
      this.inputFormat = res.inputFormat;
      this.outputFormat = res.outputFormat;
      if (res.layers?.length) this.layers = res.layers.map((layer) => new PipelineLayer(layer));
    }
  }
}



export class Pipeline {
  layers: PipelineLayer[] = [];
  inputFormat: 'string' | 'json' | 'image' = 'string';
  outputFormat: 'string' | 'json' | 'image' = 'string';
  config: PipelineConfig = new PipelineConfig();
  id: string = '';
  name: string = '';

  constructor(res?: IPipeline) {
    if (res) {
      this.layers = Array.isArray(res.layers) ? res.layers.map((layer) => new PipelineLayer(layer)): [];
      this.inputFormat = res.inputFormat || 'string';
      this.outputFormat = res.outputFormat || 'string';
      this.config = new PipelineConfig(res.config);
      this.id = res.id || '';
      this.name = res.name || '';
    }
  }
}
