import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import {
  ChatBotSource,
  CostElement,
  IAddDocumentDTO,
  IAddWebsiteDTO,
  IBulkEditLinks,
  IBulkEditLinksDTO,
  IChatBotSource,
  ICostElement,
  IFileTraining,
  IKbFilter,
  ILinks,
  ITrainLinkDTO,
  IUpdateDataSourceManyDTO,
  IUpdateSourceDTO,
  Links,
} from '../shared/models/chat-bot-source.model';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Settings } from '../shared/enums/settings.enum';
import { NO_TAG_FILTER } from '../shared/constants/kb.consts';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesService {
  constructor(private readonly apiService: ApiService, private readonly http: HttpClient, private readonly settingsService: SettingsService) {}
  private readonly dataSource$: BehaviorSubject<ChatBotSource[]> = new BehaviorSubject<ChatBotSource[]>([]);

  getAll() {
    return this.dataSource$.asObservable();
  }

  getAllDataSources(idBot: string, items: number = 100, page: number = 0, filters: IKbFilter) {
    const query = `chat-bots/${idBot}/data-sources?items=${items}&page=${page}${filters.search ? `&search=${filters.search}` : ''}${
      filters.tag ? (filters.tag.id !== NO_TAG_FILTER.id ? '&tagId=' + filters.tag.id : `&noTags=true`) : ''
    }`;
    console.log(query);
    return this.apiService
      .get<{ items: IChatBotSource[]; total: number }>(query)
      .pipe(map(({ items, total }) => ({ items: items.map((dataSource: IChatBotSource) => new ChatBotSource(dataSource)), total })));
  }

  getSingleSource(idBot: string, idSource: string): Observable<ChatBotSource> {
    return this.apiService.get<IChatBotSource>(`chat-bots/${idBot}/data-sources/${idSource}`).pipe(map((response) => new ChatBotSource(response)));
  }

  downloadSingleSource(idBot: string, idSource: string): Observable<string | undefined> {
    return this.apiService
      .get<IChatBotSource>(`chat-bots/${idBot}/data-sources/${idSource}?download=true`)
      .pipe(map((response) => response.signedUrl));
  }

  getLinksBySourceId(idSource: string) {
    return this.apiService.get<ILinks>(`data-sources/${idSource}/links`).pipe(map((response) => new Links(response)));
  }

  updateLinkBySourceId(idSource: string, body: any) {
    return this.apiService.patch(`data-sources/${idSource}/links/bulk`, body).pipe();
  }

  deleteSource(idBot: string, idSource: string) {
    return this.apiService.delete(`chat-bots/${idBot}/data-sources/${idSource}`);
  }

  createUrl(idBot: string, createBody: IAddWebsiteDTO, dryrun: boolean = false): Observable<any> {
    return this.apiService.post(`chat-bots/${idBot}/data-sources/url?${dryrun ? 'dryrun=true' : 'training=true'}`, createBody);
  }

  updateSource(idBot: string, idSource: string, body: Partial<IUpdateSourceDTO>): Observable<any> {
    return this.apiService.patch(`chat-bots/${idBot}/data-sources/${idSource}`, body);
  }

  createFileSignedUrl(idBot: string, body: any[]) {
    return this.apiService.post<IFileTraining[]>(`chat-bots/${idBot}/data-sources/file?training=true&native=true`, body);
  }

  /** @deprecated Don't use! */
  createFileLegacy(idBot: string, body: IAddDocumentDTO): Observable<any> {
    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('description', body.description);
    formData.append('type', 'FILE');
    return this.apiService.postWithProgress(`chat-bots/${idBot}/data-sources/file?training=true`, formData);
  }

  createFile(url: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put<any>(url, formData, {
      reportProgress: true,
    });
  }

  trainLinkDryRun(idBot: string, body: ITrainLinkDTO) {
    return this.apiService.post<ICostElement>(`chat-bots/${idBot}/training`, body).pipe(map((response: ICostElement) => new CostElement(response)));
  }

  trainLink(idBot: string, body: ITrainLinkDTO) {
    return this.apiService.post(`chat-bots/${idBot}/training`, body).pipe(map((response: any) => response));
  }

  updateSourceMany(idSource: string, body: IBulkEditLinksDTO) {
    return this.apiService.patch(`data-sources/${idSource}/links/bulk`, body).pipe(switchMap((value) => this.getLinksBySourceId(idSource)));
  }

  getSettings(assistantId: string) {
    return this.settingsService.getGroupSetting(Settings.KB_UI_OPTIONS, assistantId);
  }

  updateDataSourceMany(idBot: string, body: IUpdateDataSourceManyDTO) {
    return this.apiService.patch(`chat-bots/${idBot}/data-sources`, body);
  }
}
