import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAssistantBackground]',
})
export class AssistantBackgroundDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const img = this.el.nativeElement.querySelector('img.assistant-icon');
    if (img) {
      img.onload = () => this.setBackground(img);
    }
  }

  private setBackground(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx?.drawImage(img, 0, 0, img.width, img.height);
    const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
    const colors = this.extractColors(imageData?.data || new Uint8ClampedArray());
    const svg = this.createAbstractSVG(colors);
    this.renderer.setStyle(this.el.nativeElement, 'background-image', `url("data:image/svg+xml,${encodeURIComponent(svg)}")`);
    this.renderer.setStyle(this.el.nativeElement, 'background-size', 'cover');
    this.renderer.setStyle(this.el.nativeElement, 'background-repeat', 'no-repeat');

    this.adjustTextContrast(colors[0]);
  }

  private extractColors(data: Uint8ClampedArray): string[] {
    const colorCounts: any = {};
    let totalR = 0,
      totalG = 0,
      totalB = 0;
    const pixelCount = data.length / 4;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i],
        g = data[i + 1],
        b = data[i + 2];
      const rgb = `rgb(${r},${g},${b})`;
      colorCounts[rgb] = (colorCounts[rgb] || 0) + 1;
      totalR += r;
      totalG += g;
      totalB += b;
    }

    const avgColor = `rgb(${Math.round(totalR / pixelCount)},${Math.round(totalG / pixelCount)},${Math.round(totalB / pixelCount)})`;
    const dominantColors = Object.entries(colorCounts)
      .sort((a: any, b: any) => b[1] - a[1])
      .slice(0, 5)
      .map(([color]) => color);

    // Rotate the hue of the most dominant color
    const baseColor = this.rotateHue(dominantColors[0], Math.random() * 60 - 30);

    // Generate a palette of colors based on the dominant and average colors
    const palette = [
      baseColor,
      this.adjustColor(dominantColors[1], 20, 0.1),
      this.adjustColor(dominantColors[2], -20, -0.1),
      this.adjustColor(avgColor, 10, 0.15),
      this.generateComplementaryColor(baseColor),
    ];

    return palette;
  }

  private rotateHue(color: string, degrees: number): string {
    const [r, g, b] = color.match(/\d+/g)!.map(Number);
    let [h, s, l] = this.rgbToHsl(r, g, b);
    h = (h + degrees / 360) % 1;
    return `hsl(${h * 360}, ${s * 100}%, ${l * 100}%)`;
  }

  private generateComplementaryColor(color: string): string {
    const [h, s, l] = this.hslToNumbers(color);
    return `hsl(${(h + 180) % 360}, ${s}%, ${l}%)`;
  }

  private adjustColor(color: string, lightness: number, saturation: number): string {
    const [r, g, b] = color.match(/\d+/g)!.map(Number) as [number, number, number];
    const [h, s, l] = this.rgbToHsl(r, g, b);
    const newS = Math.max(0, Math.min(1, s + saturation));
    const newL = Math.max(0.2, Math.min(0.8, l + lightness / 100));

    return `hsl(${h * 360}, ${newS * 100}%, ${newL * 100}%)`;
  }

  private createAbstractSVG(colors: string[]): string {
    const svgWidth = 300;
    const svgHeight = 200;
    let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}">`;

    // Create a gradient background
    const gradient = this.createGradient(colors[0], colors[1]);
    svgContent += gradient;
    svgContent += `<rect width="100%" height="100%" fill="url(#gradient)" />`;

    // Generate organic shapes
    for (let i = 2; i < colors.length; i++) {
      svgContent += this.generateOrganicShape(colors[i], svgWidth, svgHeight);
    }

    svgContent += '</svg>';
    return svgContent;
  }

  private createGradient(color1: string, color2: string): string {
    const angle = Math.floor(Math.random() * 360);
    return `
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform="rotate(${angle} .5 .5)">
          <stop offset="0%" style="stop-color:${color1};stop-opacity:1" />
          <stop offset="100%" style="stop-color:${color2};stop-opacity:1" />
        </linearGradient>
      </defs>
    `;
  }

  private generateOrganicShape(color: string, maxWidth: number, maxHeight: number): string {
    const points = this.generateBezierPoints(maxWidth, maxHeight);
    const opacity = Math.random() * 0.4 + 0.1; // Opacity between 0.1 and 0.5
    const blur = Math.random() * 5; // Blur between 0 and 5
    return `<path d="${points}" fill="${color}" opacity="${opacity}" filter="blur(${blur}px)" />`;
  }

  private generateBezierPoints(maxWidth: number, maxHeight: number): string {
    const numPoints = Math.floor(Math.random() * 5) + 3; // 3 to 7 points
    let points = '';
    const startX = Math.random() * maxWidth;
    const startY = Math.random() * maxHeight;
    points += `M${startX},${startY} `;

    for (let i = 0; i < numPoints; i++) {
      const endX = Math.random() * maxWidth;
      const endY = Math.random() * maxHeight;
      const controlX1 = Math.random() * maxWidth;
      const controlY1 = Math.random() * maxHeight;
      const controlX2 = Math.random() * maxWidth;
      const controlY2 = Math.random() * maxHeight;
      points += `C${controlX1},${controlY1} ${controlX2},${controlY2} ${endX},${endY} `;
    }

    points += 'Z';
    return points;
  }
  private adjustTextContrast(backgroundColor: string) {
    const [h, s, l] = this.hslToNumbers(backgroundColor);
    
    // Calcola la luminosità percepita
    const perceivedBrightness = this.calculatePerceivedBrightness(h, s, l);
    
    // Usa una soglia più bassa per colori vivaci
    const threshold = s > 0.5 ? 0.65 : 0.5;
    
    const contrastClass = perceivedBrightness > threshold ? 'light-bg' : 'dark-bg';
  
    // Rimuovi entrambe le classi prima di aggiungere quella corretta
    this.renderer.removeClass(this.el.nativeElement, 'light-bg');
    this.renderer.removeClass(this.el.nativeElement, 'dark-bg');
    this.renderer.addClass(this.el.nativeElement, contrastClass);
  }
  
  private calculatePerceivedBrightness(h: number, s: number, l: number): number {
    // Converti HSL in RGB
    const [r, g, b] = this.hslToRgb(h, s, l);
    
    // Usa la formula della luminosità percepita
    // Questa formula dà più peso al verde, poi al rosso, e meno al blu
    return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  }
  
  private hslToNumbers(hsl: string): [number, number, number] {
    const [h, s, l] = hsl.match(/\d+(\.\d+)?/g)!.map(Number);
    return [h, s / 100, l / 100];
  }
  
  private hslToRgb(h: number, s: number, l: number): [number, number, number] {
    h /= 360;
    let r, g, b;
  
    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
  
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  private calculateLuminance(h: number, s: number, l: number): number {
    // Converti HSL in RGB
    const [r, g, b] = this.hslToRgb(h, s, l);

    // Calcola la luminanza utilizzando la formula standard
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

 


  private rgbToHsl(r: number, g: number, b: number): [number, number, number] {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h = 0,
      s,
      l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [h, s || 0, l];
  }
}
