import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, Settings } from 'luxon';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  constructor() {}

  transform(value?: DateTime, format?: string): string {
    return value ? value.toFormat(format || 'dd/MM/yyyy, HH:mm') : '';
  }
}
