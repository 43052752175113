import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GalleryService } from 'src/app/main/services/gallery.service';
import { ImageAi, ImagePrompt } from '../../models/gallery.model';
import { finalize } from 'rxjs';
import { dataURItoBlob } from '../../functions/utilities.functions';
import { BotsService } from 'src/app/main/services/bots.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { HttpClient } from '@angular/common/http';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';

@Component({
  selector: 'app-upload-bot-image-modal',
  templateUrl: './upload-bot-image-modal.component.html',
  styleUrl: './upload-bot-image-modal.component.scss',
})
export class UploadBotImageModalComponent {
  readonly previousImage: string | null = null;

  botId: string = '';
  currentImage: string | null = null;
  assistantDescription: string = '';
  isUpdatingImage: boolean = false;
  aiGeneratedOptions: ImageAi[] = [];

  loading: boolean = false;
  originalFile?: File;

  imageType?: 'file' | 'ai';

  constructor(
    private readonly http: HttpClient,
    private readonly ref: DynamicDialogRef,
    private readonly botsService: BotsService,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly galleryService: GalleryService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly photoEditorService: NgxPhotoEditorService
  ) {
    this.botId = this.config.data.botId;
    this.currentImage = this.config.data.currentImage;
    this.previousImage = this.config.data.currentImage;
    this.assistantDescription = this.config.data.assistantDescription;
  }

  async updateImage(event: any) {
    this.photoEditorService
      .open(event, {
        aspectRatio: 1 / 1,
        autoCropArea: 1,
        modalMaxWidth: '75%',
        modalTitle: this.mainLanguageService.instant('common.imageEditor.header'),
        applyBtnText: this.mainLanguageService.instant('common.imageEditor.apply'),
        closeBtnText: this.mainLanguageService.instant('common.imageEditor.close'),
      })
      .subscribe({
        next: (output) => {
          const reader = new FileReader();

          const file = output.file as File;
          this.originalFile = file;
          reader.readAsDataURL(this.originalFile);
          this.isUpdatingImage = true;
          reader.onload = () => {
            this.currentImage = reader.result as string;
            this.isUpdatingImage = false;
            this.imageType = 'file';
          };
        },
        error: (error) => {},
      });
  }

  generateImageWithAI() {
    this.isUpdatingImage = true;
    this.galleryService
      .createImageAi(this.getImagePrompt())
      .pipe(finalize(() => (this.isUpdatingImage = false)))
      .subscribe({
        next: (images: ImageAi[]) => {
          this.aiGeneratedOptions = images;
          this.currentImage = this.aiGeneratedOptions[0].path;
          this.imageType = 'ai';
        },
      });
  }

  private getImagePrompt(): ImagePrompt {
    return new ImagePrompt({
      quantity: 4,
      config: {
        aspectRatio: '1_1',
        prompt: this.assistantDescription,
        vendor: 'IDEOGRAM',
        modelId: 'V_2',
        imageStyle: '',
        improvePrompt: null,
        negativePrompt: '',
        originalPrompt: '',
        imageResolution: {
          width: 1024,
          height: 1024,
        },
        created: new Date(),
        resolution: '',
        seed: 0,
        visibility: 'PRIVATE',
      },
    });
  }

  saveImage() {
    if (this.botId !== 'new') {
      if (this.imageType === 'file') {
        this.saveImageFromFile();
      } else {
        this.saveImageFromAi().subscribe({
          next: (image) => this.save(image),
        });
      }
    } else {
      if (this.imageType === 'file') {
        this.ref.close({ image: this.originalFile });
      } else {
        this.loading = true;
        this.saveImageFromAi()
          .pipe(finalize(() => (this.loading = false)))
          .subscribe({
            next: (blob) => this.ref.close({ image: new File([blob], 'image.png') }),
          });
      }
    }
  }

  saveImageFromFile() {
    const blob = dataURItoBlob(this.currentImage!);
    this.save(blob);
  }

  private save(blob: Blob) {
    const formData = new FormData();
    formData.append('image', blob);

    this.loading = true;

    this.botsService
      .updateBot(this.botId ?? '', formData)
      .pipe(finalize(() => ((this.loading = false), (this.isUpdatingImage = false))))
      .subscribe({
        next: () => {
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.configurationPage.generalPage.imageUpdated'),
          });
          this.ref.close();
        },
        error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
      });
  }

  saveImageFromAi() {
    return this.http.get(this.currentImage!, { responseType: 'blob' });
  }
}
