<div *ngIf="isSidebarOpen" class="flex flex-column gap-1 max-h-full">
  <!-- <span class="flex flex-row gap-2 align-items-center sidebar-span-button" (click)="openConfigurationModal()">
    <span class="col-2 p-0 flex align-items-center justify-content-center">
      <custom-icon class="flex" [iconName]="'settings'" [type]="'material'" [class]="'text-primary'"></custom-icon>
    </span>
    <span class="col-10 flex align-items-center">
      <p class="text-primary font-semibold text-lg">Configure Chat</p>
    </span>
  </span> -->
  <span
    class="overflow-y-scroll"
    style="height: 90dvh"
    infiniteScroll
    [infiniteScrollDistance]="2"
    (scrolled)="onListThreshold($event)"
    [scrollWindow]="false"
  >
    <span class="flex justify-content-between align-items-center sticky top-0 z-3 bg-white p-3">
      <h4 class="text-primary font-semibold z-1 my-0" [ngClass]="{ 'bg-white': isSidebarOpen }" style="position: sticky; top: 1rem">
        {{ 'private.assistantPage.history' | translate }}
      </h4>
      <span class="flex gap-2 align-items-center">
        <i
          class="pi pi-search cursor-pointer"
          style="font-size: 1.2rem; color: var(--primary-color)"
          (click)="showSearchPopup = !showSearchPopup"
        ></i>
        <i class="pi pi-plus-circle cursor-pointer" style="font-size: 1.2rem; color: var(--primary-color)" (click)="onNewChatClick()"></i>
        <i class="pi pi-refresh cursor-pointer" style="font-size: 1.2rem; color: var(--primary-color)" (click)="onRefreshClick()"></i>
      </span>
    </span>
    @if (showSearchPopup) {
    <div class="w-full absolute left-0 z-3 bg-white sticky p-3 search-popup-div" style="top: 3.5rem">
      <span class="flex gap-3 align-items-center">
        <input pInputText class="w-full" placeholder="Cerca conversazione..." />
        <i class="pi pi-times cursor-pointer" style="font-size: 1.5rem" (click)="showSearchPopup = false"></i>
      </span>
    </div>
    }
    <div class="flex flex-column relative">
      <span *ngIf="loading" class="absolute z-1 h-full w-full top-0 left-0 bg-black-alpha-30 flex align-items-center justify-content-center">
        <p-progressSpinner></p-progressSpinner>
      </span>

      <span class="flex flex-column gap-2" *ngIf="conversations.length; else noConversations">
        <span *ngFor="let conversation of conversations; let i = index" class="px-2">
          <span
            class="cursor-pointer history-chat-span flex relative p-2"
            [ngClass]="{ selected: selectedConversationId === conversation.id }"
            (click)="onHistoryClick(conversation)"
            (mouseenter)="hoveredIndex = i"
            (mouseleave)="hoveredIndex = -1"
          >
            <span class="w-9">
              <p-inplace #inplace [preventClick]="true" styleClass="inplace" (onActivate)="onInplaceActivate(i)">
                <ng-template pTemplate="display">
                  <p class="font-semibold">{{ conversation.title }}</p>
                </ng-template>
                <ng-template pTemplate="content">
                  <input
                    (click)="$event.stopPropagation()"
                    [id]="'input-inplace-' + i"
                    type="text"
                    [(ngModel)]="tempNewChatName"
                    (keydown.enter)="editChatName($event, inplace, conversation)"
                    (blur)="deactivateInplace($event, inplace)"
                    pInputText
                  />
                </ng-template>
              </p-inplace>

              <p class="mb-0 pt-2">
                {{ conversation.lastQueryTimestamp ? (conversation.lastQueryTimestamp | datestringToDate : 'dd/MM/yyyy, HH:mm') : '' }}
              </p>
            </span>
            <span class="w-3 flex justify-content-end align-items-center">
              <span class="flex gap-2 align-items-center">
                <p class="my-0 text-gray-400">{{ conversation.queryCount }}</p>
                <custom-icon class="flex" [iconName]="'chat'" [type]="'material'" [class]="'text-gray-400 text-xl'"></custom-icon>
              </span>
            </span>
            <div *ngIf="hoveredIndex === i" [@inOutAnimation] class="absolute top-0 right-0 flex gap-2 align-items-center">
              <button
                pButton
                class="p-button-rounded p-button-info p-1 text-sm"
                icon="pi pi-share-alt"
                pTooltip="Condividi conversazione"
                tooltipPosition="bottom"
                (click)="openShareModal($event, conversation)"
              ></button>
              <button
                pButton
                class="p-button-rounded p-button-warning p-1 text-sm"
                icon="pi pi-pencil"
                [pTooltip]="'private.assistantPage.editTooltip' | translate"
                tooltipPosition="left"
                (click)="onEditClick($event, inplace, conversation.title)"
              ></button>
              <button
                pButton
                class="p-button-rounded p-button-danger p-1 text-sm"
                icon="pi pi-trash"
                [pTooltip]="'private.assistantPage.deleteTooltip' | translate"
                tooltipPosition="left"
                (click)="deleteChat($event, conversation)"
              ></button>
            </div>
          </span>
          <p-divider styleClass="m-0 mt-2"></p-divider>
        </span>
      </span>
      <ng-template #noConversations>
        <div class="w-full flex justify-content-center align-items-center" style="height: 70dvh">
          <h5 class="text-gray-400 text-center">{{ 'private.assistantPage.noConversations' | translate }}</h5>
        </div>
      </ng-template>
    </div>
  </span>
</div>
