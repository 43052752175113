import { MenuItem } from 'primeng/api';
import { SubscriptionPlanType } from '../types/subscription-group.type';
import { DateTime } from 'luxon';

type UnlimitedStrings = 'unlimited.plural.m' | 'unlimited.plural.f' | 'unlimited.singular';

export interface IStripeCheckoutDTO {
  subscriptionId: string;
  discountCode?: string;
}

export interface ISubscriptionMenuItem extends MenuItem {
  value: SubscriptionPlanType;
}

export interface ISubscriptionIds {
  annual: string;
  monthly: string;
}

export interface IAvailableSubscription {
  planId: string;
  planName: string;
  planType: SubscriptionPlanType;
  subscriptionId: {
    staging: ISubscriptionIds;
    production: ISubscriptionIds;
  };
  bestSeller?: boolean;
  monthlyCostAnnual: number | 'sales';
  monthlyCostMonth: number | 'sales';
  monthlyCredits: string | UnlimitedStrings;
  isMonthlyCreditsDaily?: boolean;
  users: number | UnlimitedStrings;
  createdAssistants: number | UnlimitedStrings;
  apiKeys: number | UnlimitedStrings;
  kbTraffic: string | UnlimitedStrings;
  bringYourOwnModel?: boolean;

  isBuying: boolean;
}

export interface IAvailableCredits {
  planId: string;
  creditsNumber: number;
  price: number;
  subscriptionId: {
    staging: string;
    production: string;
  };
}

interface IResource {
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  from: string;
  to: string;
  resourceId: string;
  credits: string;
  users: string;
  apiKeys: string;
  assistants: string;
  kbSize: string;
  companyId: string;
  subscriptionId: string;
}

export class Resource {
  id: string = '';
  createdAt: DateTime = DateTime.now();
  createdBy: string = '';
  updatedAt: DateTime = DateTime.now();
  from?: DateTime;
  to?: DateTime;
  resourceId: string = '';
  credits: number = 0;
  users: number = 0;
  apiKeys: number = 0;
  assistants: number = 0;
  kbSize: number = 0;
  companyId: string = '';
  subscriptionId: string = '';

  constructor(res?: IResource) {
    if (res) {
      this.id = res.id;
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.createdBy = res.createdBy;
      this.updatedAt = DateTime.fromISO(res.updatedAt);
      this.from = DateTime.fromISO(res.from);
      this.to = DateTime.fromISO(res.to);
      this.resourceId = res.resourceId;
      this.credits = +res.credits;
      this.users = +res.users;
      this.apiKeys = +res.apiKeys;
      this.assistants = +res.assistants;
      this.kbSize = +res.kbSize;
      this.companyId = res.companyId;
      this.subscriptionId = res.subscriptionId;
    }
  }
}

interface ISubscriptionDictionary {
  id: string;
  name: string;
  resources: IResource[];
}

export class SubscriptionDictionary {
  id: string = '';
  name: string = '';
  resources: Resource[] = [];

  constructor(res?: ISubscriptionDictionary) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      this.resources = res.resources.map((r) => new Resource(r));
    }
  }
}

export interface ISubscription {
  id: string;
  createdAt: string;
  updatedAt: string;
  from: string;
  to: string;
  promoCodeId: string | null;
  price: string;
  discountedPrice: string;
  recurring: boolean;
  subscriber: null;
  subscriptionDictionary: ISubscriptionDictionary;
  subscriptionDictionaryId: string;
  companyId: string;
  cancelAtPeriodEnd: boolean;
  resources: IResource[];
}

export class Subscription {
  id: string = '';
  createdAt?: DateTime;
  updatedAt?: DateTime;
  from?: DateTime;
  to?: DateTime;
  promoCodeId: string | null = null;
  price: number = 0;
  discountedPrice: number = 0;
  recurring: boolean = false;
  subscriber: null = null;
  subscriptionDictionary: SubscriptionDictionary = new SubscriptionDictionary();
  subscriptionDictionaryId: string = '';
  companyId: string = '';
  cancelAtPeriodEnd: boolean = false;
  resources: Resource[] = [];

  private _isPurchasingAgain: boolean = false;

  constructor(res?: ISubscription) {
    if (res) {
      this.id = res.id;
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.updatedAt = DateTime.fromISO(res.updatedAt);
      this.from = DateTime.fromISO(res.from);
      this.to = DateTime.fromISO(res.to);
      this.promoCodeId = res.promoCodeId;
      this.price = +res.price;
      this.discountedPrice = +res.discountedPrice;
      this.recurring = res.recurring;
      this.subscriber = res.subscriber;
      this.subscriptionDictionary = new SubscriptionDictionary(res.subscriptionDictionary);
      this.subscriptionDictionaryId = res.subscriptionDictionaryId;
      this.companyId = res.companyId;
      this.cancelAtPeriodEnd = res.cancelAtPeriodEnd;
      this.resources = this.mapResources(res.resources);
    }
  }

  private mapResources(array: IResource[]) {
    return array.map((r) => new Resource(r));
  }

  get isPurchasingAgain() {
    return this._isPurchasingAgain;
  }

  set isPurchasingAgain(value: boolean) {
    this._isPurchasingAgain = value;
  }

  setIsCanceled(value: boolean) {
    this.cancelAtPeriodEnd = value;
  }
}
