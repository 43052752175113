import { DateTime } from 'luxon';
import { TrainingStatus } from '../enums/training-status.enum';
import { CrawlingMethod } from '../types/crawling-method.type';
import { SourceTabs } from '../types/source-tabs.type';
import { SourceFileType } from '../types/source.type';
import { DataVisibility, IDataVisibility } from './data-visibility.model';
import { FilterKbTag, IKbTag, KbTag } from './kb-tags.model';

export type SingleLinkType = 'SELECTED' | 'AVAILABLE';

interface IUrlInfo {
  completed: number;
  in_progress: number;
  not_initiated: number;
}

class UrlInfo {
  completed: number = 0;
  inProgress: number = 0;
  notInitiated: number = 0;

  constructor(res?: IUrlInfo) {
    if (res) {
      this.completed = res.completed;
      this.inProgress = res.in_progress;
      this.notInitiated = res.not_initiated;
    }
  }
}

export interface IBulkEditLinks {
  id: string;
  type: SourceTabs;
  visible: boolean;
}

export class BulkEditLinks implements IBulkEditLinks {
  id: string = '';
  type: SourceTabs = 'AVAILABLE';
  visible: boolean = true;

  constructor(res?: IBulkEditLinks) {
    if (res) {
      this.id = res.id;
      this.type = res.type;
      this.visible = res.visible;
    }
  }
}

export interface IBulkEditLinksDTO {
  data: BulkEditLinks[];
}

export interface ITrainLinkDTO {
  documents?: string[];
  urls?: string[];
}

export interface ICostElement {
  costs: ICost[];
}

export class CostElement {
  costs: Cost[] = [];

  constructor(res?: ICostElement) {
    if (res) {
      this.costs = res.costs.map((icost) => new Cost(icost));
    }
  }
}

export interface ICost {
  id: string;
  cost: number;
}

export class Cost implements ICost {
  id: string = '';
  cost: number = 0;

  constructor(res?: ICost) {
    if (res) {
      this.id = res.id;
      this.cost = res.cost;
    }
  }
}

export interface ILinksStatistics {
  totalLinks: number;
  completed: number;
  progress: number;
  pending: number;
}

export interface ISingleLink {
  id: string;
  data: string;
  status: string;
}

export class SingleLink {
  id: string = '';
  data: string = '';
  trainingStatus: TrainingStatus = TrainingStatus.NOT_INITIATED;
  type: SingleLinkType;
  isLoading: boolean = false;
  cost: number = 0;

  constructor(type: SingleLinkType, res?: ISingleLink) {
    this.type = type;
    if (res) {
      this.id = res.id;
      this.data = res.data;
      this.trainingStatus = TrainingStatus[res.status as keyof typeof TrainingStatus];
    }
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setCost(cost: number) {
    this.cost = cost;
  }
}

export interface ILinks {
  links: {
    selected: ISingleLink[];
    selectedTrained: ISingleLink[];
    available: ISingleLink[];
    availableTrained: ISingleLink[];
  };
}

export class Links {
  links: SingleLink[] = [];

  constructor(res?: ILinks) {
    if (res) {
      const links = {
        links: {
          selected: this.mapSingleLinks(res.links.selected, 'SELECTED'),
          selectedTrained: this.mapSingleLinks(res.links.selectedTrained, 'SELECTED'),
          available: this.mapSingleLinks(res.links.available, 'AVAILABLE'),
          availableTrained: this.mapSingleLinks(res.links.availableTrained, 'AVAILABLE'),
        },
      }.links;
      this.links = [...links.selectedTrained, ...links.selected, ...links.availableTrained, ...links.available];
    }
  }

  private mapSingleLinks(array: ISingleLink[], type: SingleLinkType) {
    return array.map((link) => new SingleLink(type, link));
  }
}

export interface IAddWebsiteDTO {
  data: string;
  description: string;
  crawlingMethod: CrawlingMethod;
  sitemap?: string;
}

export interface IAddDocumentDTO {
  file: File;
  description: string;
}

export interface IUpdateSourceDTO {
  permanent: boolean;
  validityFrom: string | null;
  validityTo: string | null;
  updatePeriod: number;
  description: string;
}

export interface IChatBotSource {
  id: string;
  createdAt: string;
  createdBy: null;
  updatedAt: string;
  updatedBy: null;
  type: SourceFileType;
  urlType: null | string;
  fileExtension: string | null;
  data: string;
  dataVisibilities: IDataVisibility[] | null;
  description: string | null;
  pageCount: number | null;
  info?: IUrlInfo;
  permanent: boolean;
  chatBotId: string;
  crawlingMethod: CrawlingMethod | null;
  tags?: IKbTag[];
  trainingStatus: string;
  validityFrom: string | null;
  validityTo: string | null;
  updatePeriod: number | null;
  lastUpdate: string | null;
  nextUpdate: string | null;
  uploadStatus: 'UPLOAD' | 'READY' | null;
  signedUrl?: string;
}

export class ChatBotSource {
  id: string;
  type: SourceFileType;
  creationDate: DateTime = DateTime.now();
  urlType: null | string;
  fileExtension: string | null = null;
  data: string;
  dataVisibilities: DataVisibility[] | null = null;
  description: string | null = null;
  pageCount: number | null = null;
  info?: UrlInfo;
  permanent: boolean = false;
  chatBotId: string;
  tags: KbTag[] = [];
  trainingStatus: TrainingStatus = TrainingStatus.NOT_INITIATED;
  crawlingMethod: CrawlingMethod | null = null;
  validityFrom: DateTime | null = null;
  validityTo: DateTime | null = null;
  updatePeriod: number | null = null;
  lastUpdate: DateTime | null = null;
  nextUpdate: DateTime | null = null;
  uploadStatus: 'UPLOAD' | 'READY' | null = null;
  signedUrl?: string;

  cost?: Cost;
  isLoading: boolean = false;
  selected: boolean = false;

  constructor(data: IChatBotSource) {
    this.id = data.id;
    this.type = data.type;
    this.creationDate = DateTime.fromISO(data.createdAt);
    this.urlType = data.urlType;
    this.fileExtension = data.fileExtension;
    this.data = data.data;
    if (data.dataVisibilities) this.dataVisibilities = this.mapVisibilities(data.dataVisibilities);
    this.description = data.description;
    this.pageCount = data.pageCount;
    if (data.info) this.info = new UrlInfo(data.info);
    this.permanent = data.permanent;
    this.chatBotId = data.chatBotId;
    this.trainingStatus = TrainingStatus[data.trainingStatus as keyof typeof TrainingStatus];
    this.crawlingMethod = data.crawlingMethod;
    if (data.validityFrom) this.validityFrom = DateTime.fromISO(data.validityFrom);
    if (data.validityTo) this.validityTo = DateTime.fromISO(data.validityTo);
    this.updatePeriod = data.updatePeriod;
    if (data.lastUpdate) this.lastUpdate = DateTime.fromISO(data.lastUpdate);
    if (data.nextUpdate) this.nextUpdate = DateTime.fromISO(data.nextUpdate);
    if (data.tags) this.tags = this.mapKbTags(data.tags);
    this.uploadStatus = data.uploadStatus;
    this.signedUrl = data.signedUrl;
  }

  setCost(iCost: ICost) {
    this.cost = new Cost(iCost);
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setSelected(value: boolean) {
    this.selected = value;
  }

  private mapKbTags(array: IKbTag[]) {
    return array.map((tag) => new KbTag(tag));
  }

  private mapVisibilities(array: IDataVisibility[]) {
    return array.map((visibility) => new DataVisibility(visibility));
  }
}

export interface IURLDryrunResults {
  queue: boolean;
  sitemapValidity: boolean;
}

export interface IFileTraining {
  id: string;
  filename: string;
  signedUrl: string;
}

export interface ICreateDataVisibilityDTO {
  enabled: boolean;
  groupId?: string;
  userId?: string;
}

export interface IUpdateDataSourceManyDTO {
  dataSourceIds: string[];
  validityFrom?: string;
  validityTo?: string;
  visibilities: {
    groupIds: string[];
    userIds: string[];
  };
}

export interface IKbFilter {
  search: string;
  tag: FilterKbTag;
}
