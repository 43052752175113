import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { AiModelsService } from 'src/app/main/services/ai-models.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { getGroupedBotModels } from '../../functions/utilities.functions';
import { AIModel } from '../../models/ai-models.model';
import { DialogService } from 'primeng/dynamicdialog';
import { AiModelsTableModalComponent } from '../ai-models-table-modal/ai-models-table-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ai-model-select',
  templateUrl: './ai-model-select.component.html',
  styleUrls: ['./ai-model-select.component.scss'],
})
export class AiModelSelectComponent implements OnInit {
  readonly production = environment.production;
  botModels: AIModel[] = [];
  loadingAssistants: boolean = false;

  currentLanguage: string = '';
  languageSubscription?: Subscription;

  @Input() parentForm: FormGroup = new FormGroup({});
  @Input() formControl: FormControl = new FormControl();

  constructor(
    private readonly dialogService: DialogService,
    private readonly aiModelsService: AiModelsService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.languageSubscription = this.mainLanguageService.defaultLanguage$.subscribe({
      next: (lang) => {
        if (lang) this.currentLanguage = lang;
      },
    });
  }

  ngOnInit(): void {
    this.getModels();
  }

  getModels() {
    this.loadingAssistants = true;
    this.aiModelsService.getAllModels$().subscribe({
      next: (models) => {
        this.botModels = models;
      },
      complete: () => {
        this.loadingAssistants = false;
      },
    });
  }

  getValue() {
    return this.formControl.value as string;
  }

  getGroupedBotModels() {
    return getGroupedBotModels(this.botModels);
  }

  get botModelObject() {
    return this.botModels.find((c) => c.internalId === this.getValue());
  }

  patchVendor(event: DropdownChangeEvent) {
    this.parentForm.patchValue({
      vendor: this.botModels.find((model) => model.internalId === event.value)?.vendor?.internalId,
    });
  }

  get correctDescription() {
    return (
      this.botModelObject?.translations.find((t) => t.lang === this.currentLanguage)?.description ||
      this.botModelObject?.translations.find((t) => t.lang === 'en-US')?.description
    );
  }

  getMappedCapabilities() {
    return this.botModelObject!.capabilities.map((c) =>
      this.mainLanguageService.instant(`private.configurationPage.assistantPage.capabilitiesOptions.${c.toLowerCase()}`).toUpperCase()
    );
  }

  openAiModelsTableModal() {
    this.dialogService.open(AiModelsTableModalComponent, {
      styleClass: 'md:w-9 w-full',
    });
  }
}
