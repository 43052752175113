import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'companyLevel',
})
export class CompanyLevelPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case environment.level.user.level:
        return 'common.companyLevels.user';
      case environment.level.companyAdmin.level:
        return 'common.companyLevels.companyAdmin';
      case environment.level.agencyUser.level:
        return 'common.companyLevels.agencyUser';
      case environment.level.agencyAdmin.level:
        return 'common.companyLevels.agencyAgent';
      case environment.level.superAdmin.level:
        return 'common.companyLevels.superAdmin';
      default:
        return 'Undefined';
    }
  }
}
