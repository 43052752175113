import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { IAvailableSubscription, Subscription } from '../../models/subscription.model';
import { ApplyPromoCodeModalComponent } from '../apply-promo-code-modal/apply-promo-code-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrl: './pricing-card.component.scss',
})
export class PricingCardComponent {
  @Input() mainTitle: string = '';
  @Input() subtitle: string = '';
  @Input() currentSubscription?: Subscription;
  @Input() subscriptions: IAvailableSubscription[] = [];

  isYearlyPayment: boolean = true;

  promoCode: string = '';

  constructor(private readonly dialogService: DialogService, private readonly mainLanguageService: MainLanguageService) {}

  getIsNumber(price: number | string) {
    return typeof price === 'number';
  }

  getIsDefinedPrice(subscription: IAvailableSubscription) {
    return [subscription.monthlyCredits, subscription.users, subscription.createdAssistants, subscription.apiKeys, subscription.kbTraffic].every(
      (v) => !v.toString().includes('unlimited')
    );
  }

  initializeCheckout(subscription: IAvailableSubscription) {
    subscription.isBuying = true;
    this.dialogService
      .open(ApplyPromoCodeModalComponent, {
        header: this.mainLanguageService.instant('private.buySubscriptionPage.promoCodeModal.header'),
        styleClass: 'promo-code-dialog md:w-4 sm:w-6 w-10',
        data: {
          subscription,
          isYearlyPayment: this.isYearlyPayment,
        },
      })
      .onClose.subscribe({
        next: () => (subscription.isBuying = false),
      });
  }

  getCurrentActivePlan(subscription: IAvailableSubscription): { isActive: boolean; type: 'annual' | 'monthly' | null } {
    const env = environment.production ? 'production' : 'staging';

    if (this.isYearlyPayment && this.currentSubscription?.subscriptionDictionaryId === subscription.subscriptionId[env].annual) {
      return { isActive: true, type: 'annual' };
    } else if (!this.isYearlyPayment && this.currentSubscription?.subscriptionDictionaryId === subscription.subscriptionId[env].monthly) {
      return { isActive: true, type: 'monthly' };
    } else {
      return { isActive: false, type: null };
    }
  }
}
