import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, concat, first, interval, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  private updates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _hasAlreadyPrompted: boolean = false;

  constructor(private readonly updates: SwUpdate, private readonly appRef: ApplicationRef) {
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
    /** Check ogni 30 minuti */
    const checkUpdateTimer$ = interval(1 * 30 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, checkUpdateTimer$);
    everyHourOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        this.updates$.next(updateFound);
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });

    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.updates$.next(true);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  updatePwa() {
    window.location.reload();
  }

  getUpdates$(): Observable<boolean> {
    return this.updates$.asObservable();
  }

  set hasAlreadyPrompted(value: boolean) {
    this._hasAlreadyPrompted = value;
  }

  get hasAlreadyPrompted(): boolean {
    return this._hasAlreadyPrompted;
  }
}
