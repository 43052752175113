import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UpdateService } from 'src/app/main/services/update-version.service';

@Component({
  selector: 'app-update-pwa-modal',
  templateUrl: './update-pwa-modal.component.html',
  styleUrl: './update-pwa-modal.component.scss',
})
export class UpdatePwaModalComponent {
  constructor(private readonly updateService: UpdateService, private readonly ref: DynamicDialogRef) {}

  remindMeLater() {
    this.updateService.hasAlreadyPrompted = true;
    this.ref.close();
  }

  updatePwa() {
    this.updateService.updatePwa();
  }
}
