import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { IStripeCheckoutDTO, ISubscription, Subscription } from '../shared/models/subscription.model';
import { map, Subject } from 'rxjs';
import { SUBSCRIPTIONS } from '../shared/constants/subscriptions.consts';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly _currentSubscription$: Subject<Subscription> = new Subject<Subscription>();
  private readonly _getSubscriptionEmitter$: EventEmitter<void> = new EventEmitter<void>();
  readonly subscriptions = SUBSCRIPTIONS;

  constructor(private readonly apiService: ApiService) {}

  get subscriptionEmitter$() {
    return this._getSubscriptionEmitter$.asObservable();
  }

  set subscriptionEmitter$(value: any) {
    this._getSubscriptionEmitter$.emit(value);
  }

  getPersonalPlans() {
    return this.subscriptions.filter((s) => s.planType === 'PERSONAL');
  }

  getBusinessPlans() {
    return this.subscriptions.filter((s) => s.planType === 'BUSINESS');
  }

  getSubscriptions$() {
    return this.apiService.get<ISubscription[]>(`subscriptions`).pipe(map((array) => array.map((s) => new Subscription(s))));
  }

  getSubscriptionById$(subscriptionId: string) {
    return this.apiService.get<ISubscription>(`subscriptions/${subscriptionId}`).pipe(map((s) => new Subscription(s)));
  }

  checkoutStripe$(body: IStripeCheckoutDTO) {
    return this.apiService.post<{ url: string }>(`stripe/subscription/payment/checkout-session`, body);
  }

  cancelAutoRenewal$(subscriptionId: string, cancelAtPeriodEnd: boolean) {
    return this.apiService.patch<any>(`stripe/subscription/payment/${subscriptionId}`, { cancelAtPeriodEnd });
  }

  get currentSubscription$() {
    return this._currentSubscription$.asObservable();
  }

  set currentSubscription$$(value: Subscription) {
    this._currentSubscription$.next(value);
  }
}
