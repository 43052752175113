<div
  class="flex flex-column h-full page-sidebar"
  [@sidebarExpand]="sidebarState"
  [ngClass]="{ 'sidebar-collapsed': !isExpanded, 'sidebar-expanded': isExpanded }"
>
  <div class="flex p-5" [ngClass]="{ 'justify-content-between': isExpanded, 'justify-content-center': !isExpanded }">
    @if (isExpanded) {
    <p class="mb-0 font-semibold">{{ 'private.dashboardPage.marketplaceSection.filters.title' | translate | uppercase }}</p>
    }
    <custom-icon
      [type]="'material'"
      [iconName]="!isExpanded ? 'left_panel_open' : 'left_panel_close'"
      class="flex cursor-pointer"
      (click)="toggleExpand()"
    ></custom-icon>
  </div>
  @if (isExpanded) {
  <form [formGroup]="filtersForm" class="h-full flex flex-column" (ngSubmit)="onSubmit()">
    <div class="flex-grow-1">
      <div class="searchbar px-4 py-3">
        <p-iconField>
          <p-inputIcon styleClass="pi pi-search"></p-inputIcon>
          <input
            pInputText
            class="w-full bg-transparent border-none"
            formControlName="search"
            [placeholder]="'private.dashboardPage.marketplaceSection.filters.search' | translate"
          />
        </p-iconField>
      </div>
      <div class="px-4 p-3">
        <p class="mb-0">{{ 'private.dashboardPage.marketplaceSection.filters.categories' | translate }}</p>
        <div class="mt-2 flex flex-wrap gap-3">
          <p-chip
            [label]="'private.dashboardPage.marketplaceSection.tags.using' | translate"
            [styleClass]="'cursor-pointer marketplace-sidebar-category ' + (currentTagFilters.using ? 'selected' : '')"
            (click)="toggleCategory('using')"
          ></p-chip>
          <p-chip
            [label]="'private.dashboardPage.marketplaceSection.tags.official.plural' | translate"
            [styleClass]="'cursor-pointer marketplace-sidebar-category ' + (currentTagFilters.official ? 'selected' : '')"
            (click)="toggleCategory('official')"
          ></p-chip>
          <p-chip
            [label]="'private.dashboardPage.marketplaceSection.tags.legacy.plural' | translate"
            [styleClass]="'cursor-pointer marketplace-sidebar-category ' + (currentTagFilters.legacy ? 'selected' : '')"
            (click)="toggleCategory('legacy')"
          ></p-chip>
          <p-chip
            [label]="'private.dashboardPage.marketplaceSection.tags.promo' | translate"
            [styleClass]="'cursor-pointer marketplace-sidebar-category ' + (currentTagFilters.promo ? 'selected' : '')"
            (click)="toggleCategory('promo')"
          ></p-chip>
        </div>
      </div>
      <!-- <p-divider styleClass="my-0"></p-divider>
      <div class="p-3">
        <p class="mb-2">{{ 'private.dashboardPage.marketplaceSection.filters.sortBy' | translate }}</p>
        <p-dropdown styleClass="w-full " formControlName="sortBy"></p-dropdown>
      </div>
      <p-divider styleClass="my-0"></p-divider>
      <div class="p-3 flex flex-column gap-2">
        <span class="flex flex-column gap-1">
          <label>{{ 'private.historyPage.filters.from' | translate }}</label>
          <p-calendar styleClass="w-full filter-calendar" [showIcon]="true" [iconDisplay]="'input'" formControlName="from"></p-calendar>
        </span>
        <span class="flex flex-column gap-1">
          <label>{{ 'private.historyPage.filters.to' | translate }}</label>
          <p-calendar styleClass="w-full filter-calendar" [showIcon]="true" [iconDisplay]="'input'" formControlName="to"></p-calendar>
        </span>
      </div> -->
      <p-divider styleClass="my-0"></p-divider>
      <div class="p-3 flex flex-column gap-2">
        <span class="flex flex-column gap-2">
          <label>{{ 'private.historyPage.filters.rating' | translate }}</label>
          <p-rating [cancel]="true" [iconOnStyle]="{ color: 'red' }" formControlName="rating"></p-rating>
        </span>
      </div>
    </div>
  </form>
  }
</div>
