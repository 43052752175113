<div
  class="flex flex-column h-full page-sidebar"
  [@sidebarExpand]="sidebarState"
  [ngClass]="{ 'sidebar-collapsed': !isExpanded, 'sidebar-expanded': isExpanded }"
>
  <div class="flex p-3 pt-5" [ngClass]="{ 'justify-content-between': isExpanded, 'justify-content-center': !isExpanded }">
    @if (isExpanded) {
    <p class="font-semibold mb-0">{{ 'private.historyPage.filters.title' | translate | uppercase }}</p>
    }
    <custom-icon
      [type]="'material'"
      [iconName]="!isExpanded ? 'left_panel_open' : 'left_panel_close'"
      class="flex cursor-pointer"
      (click)="toggleExpand()"
    ></custom-icon>
  </div>
  @if(isExpanded) {
  <form [formGroup]="filterForm" class="flex-grow-1">
    <div class="searchbar px-4 py-3">
      <p-iconField>
        <p-inputIcon styleClass="pi pi-search"></p-inputIcon>
        <input
          pInputText
          class="w-full bg-transparent border-none"
          formControlName="search"
          [placeholder]="'private.historyPage.filters.search' | translate"
        />
      </p-iconField>
    </div>
    @if (user && user.company) {
    <span class="w-full" *level="user.company.level">
      <p-selectButton
        [allowEmpty]="false"
        class="select-preset flex flex-column p-3"
        optionValue="value"
        formControlName="own"
        [options]="companyOptions"
      >
        <ng-template let-option pTemplate>
          <p class="my-auto font-semibold">{{ option.label | translate }}</p>
        </ng-template>
      </p-selectButton>
    </span>
    }
    <!-- <div class="p-3">
      <p class="mb-2">{{ 'private.historyPage.filters.sortBy' | translate }}</p>
      <p-dropdown styleClass="w-full bg-transparent" formControlName="sortBy"></p-dropdown>
    </div> -->
    <p-divider styleClass="my-0"></p-divider>
    <div class="p-3 flex flex-column gap-2">
      <span class="flex flex-column gap-1">
        <label>{{ 'private.historyPage.filters.from' | translate }}</label>
        <p-calendar styleClass="w-full filter-calendar" [showIcon]="true" [iconDisplay]="'input'" formControlName="from"></p-calendar>
      </span>
      <span class="flex flex-column gap-1">
        <label>{{ 'private.historyPage.filters.to' | translate }}</label>
        <p-calendar styleClass="w-full filter-calendar" [showIcon]="true" [iconDisplay]="'input'" formControlName="to"></p-calendar>
      </span>
    </div>
    <p-divider styleClass="my-0"></p-divider>
    <div class="p-3 flex flex-column gap-2">
      <span class="flex flex-column gap-2">
        <label>{{ 'private.historyPage.filters.rating' | translate }}</label>
        <p-rating [cancel]="false" [iconOnStyle]="{ color: 'red' }" formControlName="rating"></p-rating>
      </span>
    </div>
  </form>
  <div class="flex justify-content-between align-items-center p-3">
    <p class="text-accent font-semibold cursor-pointer mb-0 cursor-pointer" (click)="onClearClick()">
      {{ 'private.historyPage.filters.clearAll' | translate }}
    </p>
    <button
      type="submit"
      pButton
      (click)="onSubmit()"
      [disabled]="!isButtonEnabled"
      [label]="'private.historyPage.filters.filter' | translate"
    ></button>
  </div>
  }
</div>
