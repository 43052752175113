import { Pipe, PipeTransform } from '@angular/core';
import { MarketplaceCategories, MarketplaceItem } from '../models/marketplace.model';
import { chunk } from 'lodash';

@Pipe({
  name: 'filterMarketplace',
})
export class FilterMarketplacePipe implements PipeTransform {
  transform(items: MarketplaceItem[], filter: MarketplaceCategories, toChunk: boolean, chunksSize: number = 3): any {
    return toChunk ? chunk(this.filter(items, filter), chunksSize) : this.filter(items, filter);
  }

  private filter(items: MarketplaceItem[], filter: MarketplaceCategories) {
    return items.filter((i) => {
      switch (filter) {
        case 'official':
          return i.official;
        case 'legacy':
          return i.legacy;
        case 'promo':
          return i.promo;
        case 'using':
          return i.using;
        default:
          return true;
      }
    });
  }
}
