import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { debounceTime, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { BotsService } from 'src/app/main/services/bots.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { TagsService } from 'src/app/main/services/tags.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { latestAssistantAnim } from '../../animations/latest-assistants-sidebar.anim';
import { NO_TAG_FILTER } from '../../constants/kb.consts';
import { ChatBotSource, IKbFilter } from '../../models/chat-bot-source.model';
import { ChatBot } from '../../models/chat-bot.model';
import { FilterKbTag } from '../../models/kb-tags.model';

@Component({
  selector: 'app-knowledge-base-sidebar',
  templateUrl: './knowledge-base-sidebar.component.html',
  styleUrl: './knowledge-base-sidebar.component.scss',
  animations: [latestAssistantAnim],
})
export class KnowledgeBaseSidebarComponent implements OnDestroy {
  @Output() onFilter: EventEmitter<Partial<IKbFilter>> = new EventEmitter();
  @Output() deleteSources: EventEmitter<void> = new EventEmitter();
  @Input() sourceUrls: ChatBotSource[] = [];
  @Input() selectedSources: ChatBotSource[] = [];

  searchSubject: Subject<string> = new Subject<string>();
  destroySubject: Subject<void> = new Subject<void>();

  bot?: ChatBot;
  botSubscription: Subscription = Subscription.EMPTY;
  availableTags: FilterKbTag[] = [];
  showAllTags: boolean = false;

  sidebarState: 'expanded' | 'collapsed' = 'collapsed';
  isExpanded: boolean = false;

  filtersForm: FormGroup = new FormGroup({
    search: new FormControl(''),
    tag: new FormControl<FilterKbTag | null>(null),
  });

  constructor(private readonly botsService: BotsService, private readonly tagsService: TagsService) {
    this.botSubscription = this.botsService.currentBot$.subscribe({
      next: (bot) => {
        this.bot = bot;
        this.getAvailableTags();
      },
    });

    this.searchSubject
      .pipe(
        debounceTime(300),
        takeUntil(this.destroySubject),
        tap((searchTerm) => {
          this.filtersForm.patchValue({ search: searchTerm }, { emitEvent: false });
          this.onFilter.emit(this.filtersForm.getRawValue());
        })
      )
      .subscribe();

    this.filtersForm.get('search')?.valueChanges.subscribe({
      next: (searchTerm) => {
        this.searchSubject.next(searchTerm);
      },
    });
  }

  ngOnDestroy(): void {
    this.botSubscription.unsubscribe();
  }

  toggleExpand() {
    this.sidebarState = this.sidebarState === 'collapsed' ? 'expanded' : 'collapsed';
    this.isExpanded = !this.isExpanded;
  }

  private getAvailableTags() {
    if (this.bot)
      this.tagsService.getAllTagsForBot$(this.bot?.id).subscribe({
        next: (v) => {
          const tags = v.map(
            (tag) => new FilterKbTag({ ...tag, sourcesCount: this.sourceUrls.flatMap((s) => s.tags).filter((t) => t.id === tag.id).length })
          );
          // const noTag = NO_TAG_FILTER;
          // noTag.sourcesCount = this.sourceUrls.map((s) => s.tags).filter((t) => !t.length).length;
          // this.availableTags = [noTag, ...tags];
          this.availableTags = tags;
        },
      });
  }

  toggleTag(tag: FilterKbTag) {
    const currentTag = this.filtersForm.get('tag')?.value;
    this.filtersForm.patchValue({
      tag: currentTag?.id === tag.id ? null : tag,
    });

    this.onFilter.emit(this.filtersForm.getRawValue());
  }

  getIsTagInFilter(tag: FilterKbTag) {
    return this.filtersForm.get('tag')?.value?.id === tag.id;
  }
}
