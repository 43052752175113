import { UsersService } from './../../../services/users.service';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BotsService } from 'src/app/main/services/bots.service';
import { ChatBot } from '../../models/chat-bot.model';
import { ApiKeyService } from 'src/app/main/services/api-key.service';
import { APIKey } from '../../models/api-key.model';
import { Subscription, combineLatest, distinctUntilChanged, finalize } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateApiKeyModalComponent } from '../create-api-key-modal/create-api-key-modal.component';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-api-key-table',
  templateUrl: './api-key-table.component.html',
  styleUrls: ['./api-key-table.component.scss'],
})
export class ApiKeyTableComponent implements OnDestroy {
  private bot: ChatBot = new ChatBot();
  apiKeys: APIKey[] = [];

  loading: boolean = true;
  user?: User;

  botUserSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly botsService: BotsService,
    private readonly usersService: UsersService,
    public readonly apiKeyService: ApiKeyService,
    private readonly dialogService: DialogService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.botUserSubscription = combineLatest([this.botsService.currentBot$, this.usersService.getUser$()]).subscribe({
      next: ([bot, user]) => {
        if (bot) {
          this.bot = bot;
          this.user = user;
          this.getApiKeys();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.botUserSubscription?.unsubscribe();
  }

  getApiKeys() {
    if (!this.user || !this.bot) return;
    this.apiKeyService
      .getApiKeys$(this.user.company.id, this.bot.id)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (v) => (this.apiKeys = v),
      });
  }

  createApiKey() {
    this.dialogService
      .open(CreateApiKeyModalComponent, {
        header: this.mainLanguageService.instant('private.configurationPage.integrationsPage.header'),
        styleClass: 'md:w-6 w-full',
        data: {
          assistantId: this.bot.id,
          companyId: this.user?.company.id,
        },
      })
      .onClose.subscribe({
        next: (v) => {
          if (v && v.created) {
            this.openIntegrationPage(v.id);
          }
        },
      });
  }

  openIntegrationPage(id?: string) {
    const path = ['/', 'private', this.bot.type === 'ASSISTANT' ? 'o' : '', 'assistants', this.bot?.id, 'api-key'];
    if (id) path.push(id);
    this.router.navigate(path, {
      state: {
        companyId: this.bot.company.id,
      },
    });
  }
}
