import { Type } from '@angular/core';
import { InputElementType } from '../types/input-element.type';
import { Settings } from '../enums/settings.enum';

export interface IBaseInputElement {
  id: string;
  title: string;
  description: string;
  selected?: boolean;
  textValue?: string;
  type: InputElementType;
  formControlName: string;
  options?: any[];
  component?: Type<any>;
  icon?: string;
  enabled?: boolean;
  class?: string;
  settingId?: Settings;
  tooltip?: {
    message: string;
    position?: 'top' | 'left' | 'right' | 'bottom';
  };
}

export class BaseInputElement implements IBaseInputElement {
  id: string = '';
  title: string = '';
  description: string = '';
  selected?: boolean;
  textValue?: string;
  icon?: string;
  type: InputElementType = 'radio';
  settingId?: Settings;
  formControlName: string = '';
  options?: any[];
  component?: Type<any>;
  enabled?: boolean = false;

  constructor(element?: IBaseInputElement) {
    if (element) {
      this.id = element?.id || '';
      this.title = element?.title || '';
      this.description = element?.description || '';
      this.type = element?.type || 'input';
      this.icon = element.icon;
      this.formControlName = element?.formControlName || 'name';
      this.selected = element?.selected;
      this.textValue = element?.textValue;
      this.settingId = element.settingId;
      this.options = element?.options;
      this.component = element?.component;
      this.enabled = element?.enabled;
    }
  }
}
