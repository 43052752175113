import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatBot } from '../../models/chat-bot.model';
import { ImageUuidService } from 'src/app/main/services/image-uuid.service';
import { AiModelsService } from 'src/app/main/services/ai-models.service';
import { ContextMenu } from 'primeng/contextmenu';
import { AiModelCapabilities } from '../../models/ai-models.model';

@Component({
  selector: 'app-assistant-card',
  templateUrl: './assistant-card.component.html',
  styleUrl: './assistant-card.component.scss',
})
export class AssistantCardComponent {
  readonly Capabilities = AiModelCapabilities;
  @Input() bot!: ChatBot;
  @Input() showOptions: boolean = true;
  @Input() isShowingArchived: boolean = false;
  @Input() contextMenu!: ContextMenu;
  @Output() onCardClick: EventEmitter<any> = new EventEmitter();
  @Output() onPin: EventEmitter<any> = new EventEmitter();
  @Output() onLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() onContextMenu: EventEmitter<any> = new EventEmitter();

  constructor(public readonly imageUuidService: ImageUuidService, private readonly aiModelsService: AiModelsService) {}

  getAIModel(model: string) {
    if (!this.aiModelsService.aiModels.length) return '';
    else return this.aiModelsService.aiModels.find((m) => m.internalId === model)?.name;
  }
}
