import { Pipe, PipeTransform } from '@angular/core';
import { CRAWLING_METHODS } from '../constants/crawling-methods.consts';
import { ChatBotSource } from '../models/chat-bot-source.model';

@Pipe({
  name: 'crawlingMethod',
})
export class CrawlingMethodPipe implements PipeTransform {
  transform(source: ChatBotSource): { icon: string; crawlingMethod?: string } {
    if (source.crawlingMethod) return { icon: 'language', crawlingMethod: CRAWLING_METHODS.find((m) => m.value === source.crawlingMethod)?.label };
    else return { icon: 'description', crawlingMethod: source.type };
  }
}
