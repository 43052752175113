import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keepSvgAsText',
})
export class KeepSvgAsTextPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/<svg[\s\S]*?<\/svg>/gi, (match) => {
      return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    });
  }
}
