import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-loader',
  templateUrl: './chat-loader.component.html',
  styleUrl: './chat-loader.component.scss',
})
export class ChatLoaderComponent {
  status = 'PENDING';
}
