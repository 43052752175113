import { Component, EventEmitter, Input, Output } from '@angular/core';
import { latestAssistantAnim } from '../../animations/latest-assistants-sidebar.anim';
import { ICompanyUserDropdownItem } from '../../models/company-statistics.model';
import { FILTER_PRESETS, FilterPresets } from '../../constants/date-filter-presets.items';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { TODAY } from '../../constants/generic.consts';
import { IAnalyticsFilters } from '../../models/filters.model';
import { UsersService } from 'src/app/main/services/users.service';
import { Subscription } from 'rxjs';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-analytics-filter-sidebar',
  templateUrl: './analytics-filter-sidebar.component.html',
  styleUrl: './analytics-filter-sidebar.component.scss',
  animations: [latestAssistantAnim],
})
export class AnalyticsFilterSidebarComponent {
  @Input() users: ICompanyUserDropdownItem[] = [];
  @Output() onFilter: EventEmitter<IAnalyticsFilters> = new EventEmitter();
  @Output() onGetUsers: EventEmitter<void> = new EventEmitter();
  readonly presets = FILTER_PRESETS;
  readonly companyOptions = [
    {
      label: 'private.historyPage.filters.companyOptions.yours',
      value: false,
    },
    {
      label: 'private.historyPage.filters.companyOptions.company',
      value: true,
    },
  ];

  userSubscription: Subscription = Subscription.EMPTY;
  user?: User;

  sidebarState: 'expanded' | 'collapsed' = 'collapsed';
  isExpanded: boolean = false;

  filters: IAnalyticsFilters = {
    selectedUsersId: [],
    presetSelected: 'last30days',
    dates: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
    },
    company: false,
  };

  constructor(private readonly usersService: UsersService) {
    this.userSubscription = this.usersService.getUser$().subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  toggleExpand() {
    this.sidebarState = this.sidebarState === 'expanded' ? 'collapsed' : 'expanded';
    this.isExpanded = !this.isExpanded;
  }

  setDate(event: SelectButtonChangeEvent) {
    switch (event.value as FilterPresets) {
      case 'today':
        this.filters.dates.endDate = TODAY.toJSDate();
        this.filters.dates.startDate = TODAY.toJSDate();
        break;
      case 'last7days':
        this.filters.dates.endDate = TODAY.toJSDate();
        this.filters.dates.startDate = TODAY.minus({ days: 6 }).toJSDate();
        break;
      case 'last30days':
        this.filters.dates.endDate = TODAY.toJSDate();
        this.filters.dates.startDate = TODAY.minus({ days: 30 }).toJSDate();
        break;
    }
  }

  get filterButtonDisabled() {
    return !this.filters.presetSelected && (!this.filters.dates.endDate || !this.filters.dates.startDate);
  }

  onClearClick() {
    this.filters = {
      selectedUsersId: [],
      presetSelected: 'last30days',
      dates: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
      },
      company: false,
    };
    this.onFilter.emit(this.filters);
  }
}
