import { Input, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[fallbackImage]',
})
export class FallbackImageDirective {
  @Input() fallbackImage: string = 'https://cdn.pupau.ai/assets/pupau.svg';
  asciiCalculatedNumber: number = 0;
  constructor(private readonly ref: ElementRef<HTMLImageElement>) {
    if (this.ref.nativeElement.getAttribute('data-id')) {
      this.asciiCalculatedNumber = this.assignNumber(this.ref.nativeElement.getAttribute('data-id') || '');
    }
  }

  @HostListener('error')
  handleImageError() {
    if (this.ref.nativeElement.getAttribute('data-id')) {
      this.asciiCalculatedNumber = this.assignNumber(this.ref.nativeElement.getAttribute('data-id') || '');
    }
    const element: HTMLImageElement = this.ref.nativeElement;
    if (this.asciiCalculatedNumber) {
      element.src = `https://cdn.pupau.ai/assets/fallback-icons/${this.asciiCalculatedNumber}.jpg`;
    } else {
      element.src = 'https://cdn.pupau.ai/assets/pupau.svg';
    }
    element.classList.add('bg-white');
  }

  private assignNumber(id: string): number {
    const idLowerCase = id.toLowerCase();
    const asciiSum = Array.from(idLowerCase).reduce((sum, char) => sum + char.charCodeAt(0), 0);
    console.log((asciiSum % 7) + 1);
    return (asciiSum % 7) + 1;
  }
}
