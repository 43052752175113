<div class="assistant-card card border-none shadow-none w-full p-0 flex flex-column cursor-pointer h-full" (click)="onCardClick.emit()">
  <div class="relative w-full flex p-3 info-span justify-content-between align-items-end" appAssistantBackground>
    <img class="z-2 absolute bottom-0 left-0 w-full img-filter" src="/assets/imgs/inset-shadow.png" />
    <img
      fallbackImage
      [attr.data-id]="bot.id"
      class="bot-image cursor-pointer z-3 assistant-icon"
      crossorigin="anonymous"
      [src]="
        bot.type === 'ASSISTANT'
          ? imageUuidService.getImageUrlFromUUID(bot, bot.imageUuid, 'H')
          : imageUuidService.getMarketplaceImageUrlFromUUID(bot, 'H')
      "
    />
    @if (bot.type === 'MARKETPLACE') {
    <span class="z-3 flex flex-column align-items-end">
      <p class="text-white my-0 font-semibold text-lg">{{ 4.2 }}</p>
      <p-rating [ngModel]="4.2" [cancel]="false" class="marketplace-rating" [readonly]="true"></p-rating>
    </span>
    }
  </div>
  <div class="p-3 flex flex-column flex-grow-1 justify-content-between">
    <span class="w-full flex align-items-start justify-content-between flex-grow-1">
      <p class="my-0 text-lg font-semibold max-w-6">{{ bot.name }}</p>
      @if (showOptions) {
      <span class="flex gap-2 align-items-start icons-span">
        <custom-icon
          [iconName]="bot.pinned ? 'keep_off' : 'keep'"
          class="quick-action-icon"
          (click)="$event.stopPropagation(); onPin.emit()"
        ></custom-icon>
        <span #threeDots>
          <custom-icon iconName="more_vert" class="quick-action-icon" (click)="onContextMenu.emit($event)"></custom-icon>
        </span>
      </span>
      }
    </span>
    <!--<p class="my-0 font-light">{{ '@' + bot.creatorId }}</p>  @TODO: add creator name -->
    <p class="my-4 font-light text-base-color">{{ bot.description | truncate : 100 }}</p>
    <span class="flex flex-column justify-content-end">
      <p-divider styleClass="mt-1"></p-divider>
      <div class="flex flex-row align-items-center gap-2">
        <p class="my-0 font-semibold text-base-color">{{ 'private.configurationPage.assistantPage.capabilities' | translate }}:</p>
        <span class="flex align-items-center gap-1 text-base-color">
          @if (bot.capabilities.includes(Capabilities.TEXT)) {
          <custom-icon
            [iconName]="'subject'"
            class="flex"
            [class]="'quick-action-icon capability-icon'"
            [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.text' | translate"
            tooltipPosition="top"
          ></custom-icon>
          <custom-icon
            [iconName]="'attach_file'"
            class="flex"
            [class]="'quick-action-icon capability-icon'"
            [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.attachments' | translate"
            tooltipPosition="top"
          ></custom-icon>
          } @if (bot.capabilities.includes(Capabilities.VIDEO)) {
          <custom-icon
            [iconName]="'videocam'"
            class="flex"
            [class]="'quick-action-icon capability-icon'"
            [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.video' | translate"
            tooltipPosition="top"
          ></custom-icon>
          } @if (bot.capabilities.includes(Capabilities.AUDIO)) {
          <custom-icon
            [iconName]="'volume_up'"
            class="flex"
            [class]="'quick-action-icon capability-icon'"
            [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.audio' | translate"
            tooltipPosition="top"
          ></custom-icon>
          } @if (bot.capabilities.includes(Capabilities.IMAGE)) {
          <custom-icon
            [iconName]="'image'"
            class="flex"
            [class]="'quick-action-icon capability-icon'"
            [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.image' | translate"
            tooltipPosition="top"
          ></custom-icon>
          } @if(bot.urlNavigation) {
          <custom-icon
            [iconName]="'add_link'"
            class="flex"
            [class]="'quick-action-icon capability-icon'"
            [pTooltip]="'private.configurationPage.assistantPage.capabilitiesOptions.links' | translate"
            tooltipPosition="top"
          ></custom-icon>
          }
        </span>
      </div>
      <p-divider></p-divider>
      <span class="flex sm:flex-row flex-column justify-content-between">
        <span class="sm:col-6 col-12 p-0">
          <p class="my-0 text-base-color">{{ 'private.dashboardPage.llmModel' | translate }}</p>
          <p class="my-0 text-lg font-semibold">{{ getAIModel(bot.model) }}</p>
        </span>
        <span class="sm:col-6 col-12 p-0 sm:text-right sm:pt-0 pt-3">
          <p class="my-0 text-base-color">{{ 'private.dashboardPage.price' | translate }}</p>
          <span class="flex gap-2 sm:justify-content-end justify-content-start">
            <custom-icon [iconName]="'poker_chip'" [class]="'text-sm'"></custom-icon>
            <p class="my-0 text-lg font-semibold">
              {{ bot.costMessage ? (bot.costMessage | number : '1.0-2') : '-' }}{{ 'private.dashboardPage.perMessage' | translate }}
            </p>
          </span>
        </span>
      </span>
      <p-divider></p-divider>
      <p class="my-0 text-base-color">{{ 'private.dashboardPage.lastUse' | translate }}: {{ bot.lastUseTimestamp | lastMessage | async }}</p>
    </span>
  </div>
</div>
